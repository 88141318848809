import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import '../../styles/app.scss';
import MyRouter from './Router';
import store from './store';
import Loading from '../../shared/components/LoadingSpinner';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loaded: true,
      loading: true,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Suspense fallback={<Loading />}>
        <Provider store={store}>
          <Router>
            {!loaded
                && (
                  <Loading loading={loading} />
                )}
            <div className="page__wrap">
              <MyRouter />
            </div>
          </Router>
        </Provider>
      </Suspense>
    );
  }
}

export default App;
