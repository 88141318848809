import moment from 'moment';
import {
  GET_PUBLIC_CAMPAIGN_INFO_REQUEST,
  GET_PUBLIC_CAMPAIGN_INFO_SUCCESS,
  GET_PUBLIC_CAMPAIGN_INFO_FAILURE,
  GET_PUBLIC_AIR_QUALITY_INDEX_SUCCESS,
  GET_PUBLIC_AIR_QUALITY_INDEX_REQUEST,
  GET_PUBLIC_AIR_QUALITY_INDEX_FAILURE,
  SELECT_DATE_FOR_AERYS,
} from '../actions/publicActions';

const now = moment().format('DD.MM.YYYY');

const initialState = {
  error: '',
  loading: false,
  publicCampaignInfo: {},
  publicAirQualityIndexInfo: [],
  selectedTimePeriod: {
    startDate: now,
    endDate: now,
    title: 'today',
  },
};

function publicReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PUBLIC_CAMPAIGN_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PUBLIC_CAMPAIGN_INFO_SUCCESS:
      return {
        ...state,
        publicCampaignInfo: action.payload,
        loading: false,
        error: '',
      };
    case GET_PUBLIC_CAMPAIGN_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PUBLIC_AIR_QUALITY_INDEX_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PUBLIC_AIR_QUALITY_INDEX_SUCCESS:
      return {
        ...state,
        publicAirQualityIndexInfo: action.payload,
        loading: false,
        error: '',
      };
    case GET_PUBLIC_AIR_QUALITY_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_DATE_FOR_AERYS:
      return {
        ...state,
        selectedTimePeriod: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          title: action.payload.title,
        },
      };
    default:
      return state;
  }
}

export default publicReducer;
