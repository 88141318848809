import {
  ACTIVATE_PRODUCT_REQUEST,
  ACTIVATE_PRODUCT_SUCCESS,
  ACTIVATE_PRODUCT_FAILURE,
  RESET_ACTIVATION_FORM_DATA,
} from '../actions/productsActions';

const initialState = {
  error: '',
  loading: false,
  activateProductResponse: '',
};

function productsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIVATE_PRODUCT_SUCCESS:
      return {
        ...state,
        activateProductResponse: action.payload,
        loading: false,
        error: '',
      };
    case ACTIVATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ACTIVATION_FORM_DATA:
      return initialState;
    default:
      return state;
  }
}

export default productsReducer;
