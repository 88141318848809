export const SELECT_WASTE_APP_ROUTE = 'SELECT_WASTE_APP_ROUTE';
export const DESELECT_WASTE_ROUTES = 'DESELECT_MODULE_FOR_WASTE_APP';
export const FETCH_CURRENT_CONFIG_REQUEST = 'FETCH_CURRENT_CONFIG_REQUEST';
export const FETCH_CURRENT_CONFIG_FAILURE = 'FETCH_CURRENT_CONFIG_FAILURE';
export const SET_NEW_CONFIG_REQUEST = 'SET_NEW_CONFIG_REQUEST';
export const SELECTED_PRODUCT_FOR_WASTE = 'SELECTED_PRODUCT_FOR_WASTE';
export const SET_NEW_CONFIG_FAILURE = 'SET_NEW_CONFIG_FAILURE';
export const SELECT_SENSOR_FOR_WASTE_APP = 'SELECT_SENSOR_FOR_WASTE_APP';
export const DESELECT_SENSOR_FOR_WASTE_APP = 'DESELECT_SENSOR_FOR_WASTE_APP';
export const RESET_SELECTED_SENSOR_FOR_WASTE_APP = 'RESET_SELECTED_SENSOR_FOR_WASTE_APP';
export const FETCH_SENSORS_REQUEST_WA = 'FETCH_SENSORS_REQUEST_WA';
export const FETCH_SENSORS_SUCCESS_WA = 'FETCH_SENSORS_SUCCESS_WA';
export const FETCH_SENSORS_FAILURE_WA = 'FETCH_SENSORS_FAILURE_WA';
export const SELECT_DATE_FOR_WASTE_APP = 'SELECT_DATE_FOR_WASTE_APP';

export const FETCH_MULTIPLE_SENSORS_SUCCESS_WA = 'FETCH_MULTIPLE_SENSORS_SUCCESS_WA';
export const FETCH_SENSORS_YESTERDAY_SUCCESS_WA = 'FETCH_SENSORS_YESTERDAY_SUCCESS_WA';
export const FETCH_SENSORS_WEEK_SUCCESS_WA = 'FETCH_SENSORS_WEEK_SUCCESS_WA';
export const FETCH_SENSORS_MONTH_SUCCESS_WA = 'FETCH_SENSORS_MONTH_SUCCESS_WA';
export const FETCH_SENSORS_CUSTOM_SUCCESS_WA = 'FETCH_SENSORS_CUSTOM_SUCCESS_WA';

export const FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS_WA = 'FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS_WA';
export const FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS_WA = 'FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS_WA';
export const FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS_WA = 'FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS_WA';
export const FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS_WA = 'FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS_WA';

export const RESET_ONE_PRODUCT_DATA_STATE_WA = 'RESET_ONE_PRODUCT_DATA_STATE_WA';

export const REMOVE_SENSOR_DATA_WA = 'REMOVE_SENSOR_DATA_WA';
export const RESET_MULTIPLE_PRODUCTS_DATA_STATE_WA = 'RESET_MULTIPLE_PRODUCTS_DATA_STATE_WA';

export function selectRoute(route) {
  return {
    type: SELECT_WASTE_APP_ROUTE,
    payload: route,
  };
}

export function deSelectedRoutes() {
  return {
    type: DESELECT_WASTE_ROUTES,
  };
}

export function fetchCurrentConfigRequestWA() {
  return {
    type: FETCH_CURRENT_CONFIG_REQUEST,
  };
}

export function fetchCurrentConfigFailureWA(error) {
  return {
    type: FETCH_CURRENT_CONFIG_FAILURE,
    payload: error,
  };
}

export function setNewConfigRequestWA() {
  return {
    type: SET_NEW_CONFIG_REQUEST,
  };
}

export function setNewConfigFailureWA(error) {
  return {
    type: SET_NEW_CONFIG_FAILURE,
    payload: error,
  };
}

export function addSelectedProductForManageWA(product) {
  return {
    type: SELECTED_PRODUCT_FOR_WASTE,
    payload: product,
  };
}

export function selectSensorWA(sensorName) {
  return {
    type: SELECT_SENSOR_FOR_WASTE_APP,
    payload: sensorName,
  };
}

export function deSelectSensorWA(sensorName) {
  return {
    type: DESELECT_SENSOR_FOR_WASTE_APP,
    payload: sensorName,
  };
}

export function resetSelectedSensorWA() {
  return {
    type: RESET_SELECTED_SENSOR_FOR_WASTE_APP,
  };
}

export function removeSensorDataWA(moduleId) {
  return {
    type: REMOVE_SENSOR_DATA_WA,
    payload: moduleId,
  };
}

export function fetchSensorsRequestWA() {
  return {
    type: FETCH_SENSORS_REQUEST_WA,
  };
}

export function fetchSensorsSuccessWA(data) {
  return {
    type: FETCH_SENSORS_SUCCESS_WA,
    payload: data,
  };
}

export function fetchSensorsFailureWA(error) {
  return {
    type: FETCH_SENSORS_FAILURE_WA,
    payload: error,
  };
}

export function selectDateWA(date) {
  return {
    type: SELECT_DATE_FOR_WASTE_APP,
    payload: date,
  };
}

export function fetchMultipleSensorsSuccessWA(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_SUCCESS_WA,
    payload: data,
  };
}

export function fetchSensorsYesterdaySuccessWA(data) {
  return {
    type: FETCH_SENSORS_YESTERDAY_SUCCESS_WA,
    payload: data,
  };
}

export function fetchSensorsWeekSuccessWA(data) {
  return {
    type: FETCH_SENSORS_WEEK_SUCCESS_WA,
    payload: data,
  };
}

export function fetchSensorsMonthSuccessWA(data) {
  return {
    type: FETCH_SENSORS_MONTH_SUCCESS_WA,
    payload: data,
  };
}

export function fetchSensorsCustomSuccessWA(data) {
  return {
    type: FETCH_SENSORS_CUSTOM_SUCCESS_WA,
    payload: data,
  };
}

export function fetchMultipleSensorsYesterdaySuccessWA(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS_WA,
    payload: data,
  };
}

export function fetchMultipleSensorsWeekSuccessWA(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS_WA,
    payload: data,
  };
}

export function fetchMultipleSensorsMonthSuccessWA(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS_WA,
    payload: data,
  };
}

export function fetchMultipleSensorsCustomSuccessWA(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS_WA,
    payload: data,
  };
}

export function resetSensorDataStateWA() {
  return {
    type: RESET_ONE_PRODUCT_DATA_STATE_WA,
  };
}

export function resetMultipleSensorsDataStateWA() {
  return {
    type: RESET_MULTIPLE_PRODUCTS_DATA_STATE_WA,
  };
}
