export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';
export const GET_CUSTOMER_APPS_REQUEST = 'GET_CUSTOMER_APPS_REQUEST';
export const GET_CUSTOMER_APPS_SUCCESS = 'GET_CUSTOMER_APPS_SUCCESS';
export const GET_CUSTOMER_APPS_FAILURE = 'GET_CUSTOMER_APPS_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';
export const RESET_USER_PASSWORD_RESPONSE = 'RESET_USER_PASSWORD_RESPONSE';
export const RESET_USER_PROFILE_RESPONSE = 'RESET_USER_PROFILE_RESPONSE';
export const RESET_AVATAR_UPLOAD_RESPONSE = 'RESET_AVATAR_UPLOAD_RESPONSE';
export const UPDATE_BACKGROUND_REQUEST = 'UPDATE_BACKGROUND_REQUEST';
export const UPDATE_BACKGROUND_SUCCESS = 'UPDATE_BACKGROUND_SUCCESS';
export const UPDATE_BACKGROUND_FAILURE = 'UPDATE_BACKGROUND_FAILURE';
export const RESET_BACKGROUND_RESPONSE = 'RESET_BACKGROUND_RESPONSE';
export const UPDATE_TOOLTIP_STATUS_REQUEST = 'UPDATE_TOOLTIP_STATUS_REQUEST';
export const UPDATE_TOOLTIP_STATUS_SUCCESS = 'UPDATE_TOOLTIP_STATUS_SUCCESS';
export const UPDATE_TOOLTIP_STATUS_FAILURE = 'UPDATE_TOOLTIP_STATUS_FAILURE';
export const FETCH_ACTIVE_SESSIONS_REQUEST = 'FETCH_ACTIVE_SESSIONS_REQUEST';
export const FETCH_ACTIVE_SESSIONS_SUCCESS = 'FETCH_ACTIVE_SESSIONS_SUCCESS';
export const FETCH_ACTIVE_SESSIONS_FAILURE = 'FETCH_ACTIVE_SESSIONS_FAILURE';
export const DELETE_SESSIONS_REQUEST = 'DELETE_SESSIONS_REQUEST';
export const DELETE_SESSIONS_SUCCESS = 'DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_FAILURE = 'DELETE_SESSIONS_FAILURE';
export const RESET_SESSIONS_RESPONSE = 'RESET_SESSIONS_RESPONSE';
export const NOTIFICATIONS_API_REQUEST = 'NOTIFICATIONS_API_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_API_FAILURE = 'NOTIFICATIONS_API_FAILURE';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const UPDATE_2FA_SUCCESS = 'UPDATE_2FA_SUCCESS';
export const UPDATE_2FA_REQUEST = 'UPDATE_2FA_REQUEST';
export const UPDATE_2FA_FAILURE = 'UPDATE_2FA_FAILURE';
export const REGENERATE_API_KEY_SUCCESS = 'REGENERATE_API_KEY_SUCCESS';
export const REGENERATE_API_KEY_REQUEST = 'REGENERATE_API_KEY_REQUEST';
export const REGENERATE_API_KEY_FAILURE = 'REGENERATE_API_KEY_FAILURE';
export const GET_ALL_CUSTOMERS_FROM_COMPANY_SUCCESS = 'GET_ALL_CUSTOMERS_FROM_COMPANY_SUCCESS';
export const GET_ALL_CUSTOMERS_FROM_COMPANY_REQUEST = 'GET_ALL_CUSTOMERS_FROM_COMPANY_REQUEST';
export const GET_ALL_CUSTOMERS_FROM_COMPANY_FAILURE = 'GET_ALL_CUSTOMERS_FROM_COMPANY_FAILURE';
export const SELECT_FILTER_FOR_COMPANY_USERS_LIST = 'SELECT_FILTER_FOR_COMPANY_USERS_LIST';
export const SELECTED_USER_FOR_EDIT = 'SELECTED_USER_FOR_EDIT';
export const EDIT_COMPANY_USER_FIRST_NAME = 'EDIT_COMPANY_USER_FIRST_NAME';
export const EDIT_COMPANY_USER_LAST_NAME = 'EDIT_COMPANY_USER_LAST_NAME';
export const EDIT_COMPANY_USER_EMAIL = 'EDIT_COMPANY_USER_EMAIL';
export const EDIT_COMPANY_USER_PROFILE_IMAGE = 'EDIT_COMPANY_USER_PROFILE_IMAGE';
export const EDIT_COMPANY_USER_ROLE = 'EDIT_COMPANY_USER_ROLE';
export const RESET_COMPANY_USER_INFO = 'RESET_COMPANY_USER_INFO';
export const RESET_PUBLIC_API_RESPONSE = 'RESET_PUBLIC_API_RESPONSE';
export const CHECK_CUSTOMER_EMAIL_REQUEST = 'CHECK_CUSTOMER_EMAIL_REQUEST';
export const CHECK_CUSTOMER_EMAIL_SUCCESS = 'CHECK_CUSTOMER_EMAIL_SUCCESS';
export const CHECK_CUSTOMER_EMAIL_FAILURE = 'CHECK_CUSTOMER_EMAIL_FAILURE';
export const ADD_NEW_CUSTOMER_EMAIL = 'ADD_NEW_CUSTOMER_EMAIL';
export const ADD_ROLE_TO_CUSTOMER = 'ADD_ROLE_TO_CUSTOMER';
export const INVITE_TO_COMPANY_REQUEST = 'INVITE_TO_COMPANY_REQUEST';
export const INVITE_TO_COMPANY_SUCCESS = 'INVITE_TO_COMPANY_SUCCESS';
export const INVITE_TO_COMPANY_FAILURE = 'INVITE_TO_COMPANY_FAILURE';
export const RESET_NEW_CUSTOMER_RESPONSE = 'RESET_NEW_CUSTOMER_RESPONSE';
export const ADD_NEW_CUSTOMER_LAST_NAME = 'ADD_NEW_CUSTOMER_LAST_NAME';
export const ADD_NEW_CUSTOMER_FIRST_NAME = 'ADD_NEW_CUSTOMER_FIRST_NAME';
export const ADD_NEW_CUSTOMER_PERMISSIONS = 'ADD_NEW_CUSTOMER_PERMISSIONS';
export const SYNC_PERMISSIONS_SUCCESS = 'SYNC_PERMISSIONS_SUCCESS';
export const SYNC_PERMISSIONS_REQUEST = 'SYNC_PERMISSIONS_REQUEST';
export const SYNC_PERMISSIONS_FAILURE = 'SYNC_PERMISSIONS_FAILURE';
export const REMOVE_CUSTOMER_REQUEST = 'REMOVE_CUSTOMER_REQUEST';
export const REMOVE_CUSTOMER_SUCCESS = 'REMOVE_CUSTOMER_SUCCESS';
export const REMOVE_CUSTOMER_FAILURE = 'REMOVE_CUSTOMER_FAILURE';
export const SELECT_PERMISSION = 'SELECT_PERMISSION';
export const DESELECT_PERMISSION = 'DESELECT_PERMISSION';
export const RESET_PERMISSIONS_RESPONSE = 'RESET_PERMISSIONS_RESPONSE';
export const GET_ALL_PERMISSIONS_REQUEST = 'GET_ALL_PERMISSIONS_REQUEST';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAILURE = 'GET_ALL_PERMISSIONS_FAILURE';
export const SELECT_NEW_CUSTOMER_PERMISSION = 'SELECT_NEW_CUSTOMER_PERMISSION';
export const DESELECT_NEW_CUSTOMER_PERMISSION = 'DESELECT_NEW_CUSTOMER_PERMISSION';
export const ADD_NEW_CUSTOMER_APPLICATION = 'ADD_NEW_CUSTOMER_APPLICATION';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'ACCEPT_TERMS_FAILURE';
export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';

export function getAllCustomersFromCompanyRequest() {
  return {
    type: GET_ALL_CUSTOMERS_FROM_COMPANY_REQUEST,
  };
}

export function getAllCustomersFromCompanySuccess(companyUsers) {
  return {
    type: GET_ALL_CUSTOMERS_FROM_COMPANY_SUCCESS,
    payload: companyUsers,
  };
}

export function getAllCustomersFromCompanyFailure(error) {
  return {
    type: GET_ALL_CUSTOMERS_FROM_COMPANY_FAILURE,
    payload: error,
  };
}

export function fetchUserDataRequest() {
  return {
    type: FETCH_USER_DATA_REQUEST,
  };
}

export function fetchUserDataSuccess(user) {
  return {
    type: FETCH_USER_DATA_SUCCESS,
    payload: user,
  };
}

export function fetchUserDataFailure(error) {
  return {
    type: FETCH_USER_DATA_FAILURE,
    payload: error,
  };
}

export function getCustomerAppsRequest() {
  return {
    type: GET_CUSTOMER_APPS_REQUEST,
  };
}

export function getCustomerAppsSuccess(data) {
  return {
    type: GET_CUSTOMER_APPS_SUCCESS,
    payload: data,
  };
}

export function getCustomerAppsFailure(error) {
  return {
    type: GET_CUSTOMER_APPS_FAILURE,
    payload: error,
  };
}

export function updateUserRequest() {
  return {
    type: UPDATE_USER_REQUEST,
  };
}

export function updateUserSuccess(data) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data,
  };
}

export function updateUserFailure(error) {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
}

export function updateUserPasswordRequest() {
  return {
    type: UPDATE_USER_PASSWORD_REQUEST,
  };
}

export function updateUserPasswordSuccess(data) {
  return {
    type: UPDATE_USER_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function updateUserPasswordFailure(error) {
  return {
    type: UPDATE_USER_PASSWORD_FAILURE,
    payload: error,
  };
}

export function resetPasswordResponse(data) {
  return {
    type: RESET_USER_PASSWORD_RESPONSE,
    payload: data,
  };
}

export function resetUserProfileResponse(data) {
  return {
    type: RESET_USER_PROFILE_RESPONSE,
    payload: data,
  };
}

export function resetAvatarUploadedResponse() {
  return {
    type: RESET_AVATAR_UPLOAD_RESPONSE,
  };
}

export function updateBackgroundRequest() {
  return {
    type: UPDATE_BACKGROUND_REQUEST,
  };
}

export function updateBackgroundSuccess(data) {
  return {
    type: UPDATE_BACKGROUND_SUCCESS,
    payload: data,
  };
}

export function updateBackgroundFailure(error) {
  return {
    type: UPDATE_BACKGROUND_FAILURE,
    payload: error,
  };
}

export function resetBackgroundResponse() {
  return {
    type: RESET_BACKGROUND_RESPONSE,
  };
}

export function updateTooltipStatusRequest() {
  return {
    type: UPDATE_TOOLTIP_STATUS_REQUEST,
  };
}

export function updateTooltipStatusSuccess(data) {
  return {
    type: UPDATE_TOOLTIP_STATUS_SUCCESS,
    payload: data,
  };
}

export function updateTooltipStatusFailure(error) {
  return {
    type: UPDATE_TOOLTIP_STATUS_FAILURE,
    payload: error,
  };
}

export function fetchActiveSessionsRequest() {
  return {
    type: FETCH_ACTIVE_SESSIONS_REQUEST,
  };
}

export function fetchActiveSessionsSuccess(sessions) {
  return {
    type: FETCH_ACTIVE_SESSIONS_SUCCESS,
    payload: sessions,
  };
}

export function fetchActiveSessionsFailure(error) {
  return {
    type: FETCH_ACTIVE_SESSIONS_FAILURE,
    payload: error,
  };
}

export function deleteSessionsRequest() {
  return {
    type: DELETE_SESSIONS_REQUEST,
  };
}

export function deleteSessionsSuccess(message) {
  return {
    type: DELETE_SESSIONS_SUCCESS,
    payload: message,
  };
}

export function deleteSessionsFailure(error) {
  return {
    type: DELETE_SESSIONS_FAILURE,
    payload: error,
  };
}

export function resetSessionsResponse() {
  return {
    type: RESET_SESSIONS_RESPONSE,
  };
}

export function notificationsApiRequest(message) {
  return {
    type: NOTIFICATIONS_API_REQUEST,
    payload: message,
  };
}

export function getNotificationsSuccess(data) {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
}

export function notificationsApiFailure(error) {
  return {
    type: NOTIFICATIONS_API_FAILURE,
    payload: error,
  };
}

export function deleteNotificationSuccess(msg) {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: msg,
  };
}

export function update2FAFailure(error) {
  return {
    type: UPDATE_2FA_FAILURE,
    payload: error,
  };
}

export function update2FARequest(data) {
  return {
    type: UPDATE_2FA_REQUEST,
    payload: data,
  };
}

export function update2FASuccess(data) {
  return {
    type: UPDATE_2FA_SUCCESS,
    payload: data,
  };
}

export function selectFilter(data) {
  return {
    type: SELECT_FILTER_FOR_COMPANY_USERS_LIST,
    payload: data,
  };
}

export function selectedUserForEdit(user) {
  return {
    type: SELECTED_USER_FOR_EDIT,
    payload: user,
  };
}

export function editCompanyUserFirstName(firstName) {
  return {
    type: EDIT_COMPANY_USER_FIRST_NAME,
    payload: firstName,
  };
}

export function editCompanyUserLastName(lastName) {
  return {
    type: EDIT_COMPANY_USER_LAST_NAME,
    payload: lastName,
  };
}

export function editCompanyUserEmail(email) {
  return {
    type: EDIT_COMPANY_USER_EMAIL,
    payload: email,
  };
}

export function editCompanyUserProfileImage(image) {
  return {
    type: EDIT_COMPANY_USER_PROFILE_IMAGE,
    payload: image,
  };
}

export function editCompanyUserRole(role) {
  return {
    type: EDIT_COMPANY_USER_ROLE,
    payload: role,
  };
}

export function resetCompanyUserInfo() {
  return {
    type: RESET_COMPANY_USER_INFO,
  };
}

export function checkCustomerEmailFailure(error) {
  return {
    type: CHECK_CUSTOMER_EMAIL_FAILURE,
    payload: error,
  };
}

export function checkCustomerEmailRequest(data) {
  return {
    type: CHECK_CUSTOMER_EMAIL_REQUEST,
    payload: data,
  };
}

export function checkCustomerEmailSuccess(data) {
  return {
    type: CHECK_CUSTOMER_EMAIL_SUCCESS,
    payload: data,
  };
}

export function addNewCustomerEmail(data) {
  return {
    type: ADD_NEW_CUSTOMER_EMAIL,
    payload: data,
  };
}

export function addRoleToCustomer(role) {
  return {
    type: ADD_ROLE_TO_CUSTOMER,
    payload: role,
  };
}

export function inviteToCompanySuccess(customer) {
  return {
    type: INVITE_TO_COMPANY_SUCCESS,
    payload: customer,
  };
}

export function inviteToCompanyRequest(data) {
  return {
    type: INVITE_TO_COMPANY_REQUEST,
    payload: data,
  };
}

export function inviteToCompanyFailure(error) {
  return {
    type: INVITE_TO_COMPANY_FAILURE,
    payload: error,
  };
}

export function resetNewCustomerResponse() {
  return {
    type: RESET_NEW_CUSTOMER_RESPONSE,
  };
}

export function addNewCustomerFirstName(name) {
  return {
    type: ADD_NEW_CUSTOMER_FIRST_NAME,
    payload: name,
  };
}

export function addNewCustomerLastName(name) {
  return {
    type: ADD_NEW_CUSTOMER_LAST_NAME,
    payload: name,
  };
}

export function addNewCustomerPermission(permission) {
  return {
    type: ADD_NEW_CUSTOMER_PERMISSIONS,
    payload: permission,
  };
}

export function addNewCustomerApplication(application) {
  return {
    type: ADD_NEW_CUSTOMER_APPLICATION,
    payload: application,
  };
}

export function syncPermissionsSuccess(permissions) {
  return {
    type: SYNC_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
}

export function syncPermissionsRequest(data) {
  return {
    type: SYNC_PERMISSIONS_REQUEST,
    payload: data,
  };
}

export function syncPermissionsFailure(error) {
  return {
    type: SYNC_PERMISSIONS_FAILURE,
    payload: error,
  };
}

export function removeCustomerSuccess(customer) {
  return {
    type: REMOVE_CUSTOMER_SUCCESS,
    payload: customer,
  };
}

export function removeCustomerRequest(data) {
  return {
    type: REMOVE_CUSTOMER_REQUEST,
    payload: data,
  };
}

export function removeCustomerFailure(error) {
  return {
    type: REMOVE_CUSTOMER_FAILURE,
    payload: error,
  };
}

export function selectPermission(permission) {
  return {
    type: SELECT_PERMISSION,
    payload: permission,
  };
}

export function deSelectPermission(permission) {
  return {
    type: DESELECT_PERMISSION,
    payload: permission,
  };
}

export function selectNewCustomerPermission(permission) {
  return {
    type: SELECT_NEW_CUSTOMER_PERMISSION,
    payload: permission,
  };
}

export function deSelectNewCustomerPermission(permission) {
  return {
    type: DESELECT_NEW_CUSTOMER_PERMISSION,
    payload: permission,
  };
}

export function resetPermissionsResponse() {
  return {
    type: RESET_PERMISSIONS_RESPONSE,
  };
}

export function resetPublicApiResponse() {
  return {
    type: RESET_PUBLIC_API_RESPONSE,
  };
}

export function getAllPermissionsRequest() {
  return {
    type: GET_ALL_PERMISSIONS_REQUEST,
  };
}

export function getAllPermissionsSuccess(permissions) {
  return {
    type: GET_ALL_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
}

export function getAllPermissionsFailure(error) {
  return {
    type: GET_ALL_PERMISSIONS_FAILURE,
    payload: error,
  };
}

export function reGenerateApiKeyRequest() {
  return {
    type: REGENERATE_API_KEY_REQUEST,
  };
}

export function reGenerateApiKeySuccess(key) {
  return {
    type: REGENERATE_API_KEY_SUCCESS,
    payload: key,
  };
}

export function reGenerateApiKeyFailure(error) {
  return {
    type: REGENERATE_API_KEY_FAILURE,
    payload: error,
  };
}

export function acceptTermsRequest() {
  return {
    type: ACCEPT_TERMS_REQUEST,
  };
}

export function acceptTermsSuccess(data) {
  return {
    type: ACCEPT_TERMS_SUCCESS,
    payload: data,
  };
}

export function acceptTermsFailure(error) {
  return {
    type: ACCEPT_TERMS_FAILURE,
    payload: error,
  };
}
