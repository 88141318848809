export const FETCH_DEPLOYED_PRODUCTS_REQUEST = 'FETCH_DEPLOYED_PRODUCTS_REQUEST';
export const FETCH_DEPLOYED_PRODUCTS_SUCCESS = 'FETCH_DEPLOYED_PRODUCTS_SUCCESS';
export const FETCH_DEPLOYED_PRODUCTS_FAILURE = 'FETCH_DEPLOYED_PRODUCTS_FAILURE';
export const SELECT_FILTER_FOR_PRODUCTS_LIST = 'SELECT_FILTER_FOR_PRODUCTS_LIST';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const DESELECT_PRODUCT = 'DESELECT_PRODUCT';
export const SELECT_ALL_PRODUCTS = 'SELECT_ALL_PRODUCTS';
export const DESELECT_ALL_PRODUCTS = 'DESELECT_ALL_PRODUCTS';
export const REQUEST_GEO_LOCATING_SUCCESS = 'REQUEST_GEO_LOCATING_SUCCESS';
export const UPDATE_NICKNAME_REQUEST = 'UPDATE_NICKNAME_REQUEST';
export const UPDATE_NICKNAME_SUCCESS = 'UPDATE_NICKNAME_SUCCESS';
export const UPDATE_NICKNAME_FAILURE = 'UPDATE_NICKNAME_FAILURE';
export const RESET_NICKNAME_UPDATED_RESPONSE = 'RESET_NICKNAME_UPDATED_RESPONSE';

export function updateNicknameRequest() {
  return {
    type: UPDATE_NICKNAME_REQUEST,
  };
}

export function updateNicknameSuccess(data) {
  return {
    type: UPDATE_NICKNAME_SUCCESS,
    payload: data,
  };
}

export function updateNicknameFailure(error) {
  return {
    type: UPDATE_NICKNAME_FAILURE,
    payload: error,
  };
}

export function resetNicknameResponse() {
  return {
    type: RESET_NICKNAME_UPDATED_RESPONSE,
  };
}

export function fetchDeployedProductsRequest() {
  return {
    type: FETCH_DEPLOYED_PRODUCTS_REQUEST,
  };
}

export function fetchDeployedProductsSuccess(data) {
  return {
    type: FETCH_DEPLOYED_PRODUCTS_SUCCESS,
    payload: data,
  };
}

export function fetchDeployedProductsFailure(error) {
  return {
    type: FETCH_DEPLOYED_PRODUCTS_FAILURE,
    payload: error,
  };
}

export function selectFilter(data) {
  return {
    type: SELECT_FILTER_FOR_PRODUCTS_LIST,
    payload: data,
  };
}

export function selectProduct(id) {
  return {
    type: SELECT_PRODUCT,
    payload: id,
  };
}

export function deSelectProduct(id) {
  return {
    type: DESELECT_PRODUCT,
    payload: id,
  };
}

export function selectAllProducts(allIDs) {
  return {
    type: SELECT_ALL_PRODUCTS,
    payload: allIDs,
  };
}

export function deSelectAllProducts() {
  return {
    type: DESELECT_ALL_PRODUCTS,
  };
}

export function requestGeoLocatingSuccess(data) {
  return {
    type: REQUEST_GEO_LOCATING_SUCCESS,
    payload: data,
  };
}
