import moment from 'moment';
import {
  SELECT_DATE_FOR_DISTRIBUTOR_APP,
  SELECT_ROUTE_FOR_DISTRIBUTOR_APP,
  GET_DISTRIBUTOR_PRODUCTS_REQUEST,
  GET_DISTRIBUTOR_PRODUCTS_SUCCESS,
  GET_DISTRIBUTOR_PRODUCTS_FAILURE,
  GET_PRODUCT_STATUS_REQUEST,
  GET_PRODUCT_STATUS_SUCCESS,
  GET_PRODUCT_STATUS_YESTERDAY_SUCCESS,
  GET_PRODUCT_STATUS_WEEK_SUCCESS,
  GET_PRODUCT_STATUS_MONTH_SUCCESS,
  GET_PRODUCT_STATUS_FAILURE,
} from '../actions/distributorAppActions';

const now = moment().format('DD.MM.YYYY');

const initialState = {
  loading: false,
  distributorProducts: [],
  productsInfo: [],
  productsInfoYesterday: [],
  productsInfoWeek: [],
  productsInfoMonth: [],
  distributorRouteSelected: '',
  errorMessage: '',
  selectedTimePeriod: {
    startDate: now,
    endDate: now,
    title: 'today',
  },
};

function distributorAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_DATE_FOR_DISTRIBUTOR_APP:
      return {
        ...state,
        selectedTimePeriod: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          title: action.payload.title,
        },
      };
    case SELECT_ROUTE_FOR_DISTRIBUTOR_APP:
      return {
        ...state,
        distributorRouteSelected: action.payload,
        loading: false,
      };
    case GET_DISTRIBUTOR_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case GET_DISTRIBUTOR_PRODUCTS_SUCCESS:
      return {
        ...state,
        distributorProducts: action.payload,
        loading: false,
        errorMessage: '',
      };
    case GET_DISTRIBUTOR_PRODUCTS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case GET_PRODUCT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case GET_PRODUCT_STATUS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case GET_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        productsInfo: action.payload,
        loading: false,
      };
    case GET_PRODUCT_STATUS_YESTERDAY_SUCCESS:
      return {
        ...state,
        productsInfoYesterday: action.payload,
        loading: false,
      };
    case GET_PRODUCT_STATUS_WEEK_SUCCESS:
      return {
        ...state,
        productsInfoWeek: action.payload,
        loading: false,
      };
    case GET_PRODUCT_STATUS_MONTH_SUCCESS:
      return {
        ...state,
        productsInfoMonth: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default distributorAppReducer;
