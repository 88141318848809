export const SELECT_SENSOR_FOR_SENSE_APP = 'SELECT_SENSOR_FOR_SENSE_APP';
export const DESELECT_SENSOR_FOR_SENSE_APP = 'DESELECT_SENSOR_FOR_SENSE_APP';
export const RESET_SELECTED_SENSOR_FOR_SENSE_APP = 'RESET_SELECTED_SENSOR_FOR_SENSE_APP';
export const FETCH_SENSORS_REQUEST = 'FETCH_SENSORS_REQUEST';
export const FETCH_SENSORS_SUCCESS = 'FETCH_SENSORS_SUCCESS';
export const FETCH_SENSORS_FAILURE = 'FETCH_SENSORS_FAILURE';
export const SELECT_DATE_FOR_SENSE_APP = 'SELECT_DATE_FOR_SENSE_APP';

export const FETCH_MULTIPLE_SENSORS_SUCCESS = 'FETCH_MULTIPLE_SENSORS_SUCCESS';
export const FETCH_SENSORS_YESTERDAY_SUCCESS = 'FETCH_SENSORS_YESTERDAY_SUCCESS';
export const FETCH_SENSORS_WEEK_SUCCESS = 'FETCH_SENSORS_WEEK_SUCCESS';
export const FETCH_SENSORS_MONTH_SUCCESS = 'FETCH_SENSORS_MONTH_SUCCESS';
export const FETCH_SENSORS_CUSTOM_SUCCESS = 'FETCH_SENSORS_CUSTOM_SUCCESS';

export const FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS = 'FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS';
export const FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS = 'FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS';
export const FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS = 'FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS';
export const FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS = 'FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS';

export const RESET_ONE_PRODUCT_DATA_STATE = 'RESET_ONE_PRODUCT_DATA_STATE';

export const REMOVE_SENSOR_DATA = 'REMOVE_SENSOR_DATA';
export const RESET_MULTIPLE_PRODUCTS_DATA_STATE = 'RESET_MULTIPLE_PRODUCTS_DATA_STATE';

export function selectSensor(sensorName) {
  return {
    type: SELECT_SENSOR_FOR_SENSE_APP,
    payload: sensorName,
  };
}

export function deSelectSensor(sensorName) {
  return {
    type: DESELECT_SENSOR_FOR_SENSE_APP,
    payload: sensorName,
  };
}

export function resetSelectedSensor() {
  return {
    type: RESET_SELECTED_SENSOR_FOR_SENSE_APP,
  };
}

export function removeSensorData(moduleId) {
  return {
    type: REMOVE_SENSOR_DATA,
    payload: moduleId,
  };
}

export function fetchSensorsRequest() {
  return {
    type: FETCH_SENSORS_REQUEST,
  };
}

export function fetchSensorsSuccess(data) {
  return {
    type: FETCH_SENSORS_SUCCESS,
    payload: data,
  };
}

export function fetchSensorsFailure(error) {
  return {
    type: FETCH_SENSORS_FAILURE,
    payload: error,
  };
}

export function selectDate(date) {
  return {
    type: SELECT_DATE_FOR_SENSE_APP,
    payload: date,
  };
}

export function fetchMultipleSensorsSuccess(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_SUCCESS,
    payload: data,
  };
}

export function fetchSensorsYesterdaySuccess(data) {
  return {
    type: FETCH_SENSORS_YESTERDAY_SUCCESS,
    payload: data,
  };
}

export function fetchSensorsWeekSuccess(data) {
  return {
    type: FETCH_SENSORS_WEEK_SUCCESS,
    payload: data,
  };
}

export function fetchSensorsMonthSuccess(data) {
  return {
    type: FETCH_SENSORS_MONTH_SUCCESS,
    payload: data,
  };
}

export function fetchSensorsCustomSuccess(data) {
  return {
    type: FETCH_SENSORS_CUSTOM_SUCCESS,
    payload: data,
  };
}

export function fetchMultipleSensorsYesterdaySuccess(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS,
    payload: data,
  };
}

export function fetchMultipleSensorsWeekSuccess(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS,
    payload: data,
  };
}

export function fetchMultipleSensorsMonthSuccess(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS,
    payload: data,
  };
}

export function fetchMultipleSensorsCustomSuccess(data) {
  return {
    type: FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS,
    payload: data,
  };
}

export function resetSensorDataState() {
  return {
    type: RESET_ONE_PRODUCT_DATA_STATE,
  };
}

export function resetMultipleSensorsDataState() {
  return {
    type: RESET_MULTIPLE_PRODUCTS_DATA_STATE,
  };
}
