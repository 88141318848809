export default function authHeader(noContentType) {
  const auth = localStorage.getItem('auth');

  if (auth) {
    const item = JSON.parse(auth);
    if (noContentType) {
      return {
        Accept: 'application/json',
        Authorization: `Bearer ${item.token}`,
      };
    }
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${item.token}`,
    };
  }

  return {};
}
