export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export function fetchCountriesRequest() {
  return {
    type: FETCH_COUNTRIES_REQUEST,
  };
}

export function fetchCountriesSuccess(countries) {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    payload: countries,
  };
}

export function fetchCountriesFailure(error) {
  return {
    type: FETCH_COUNTRIES_FAILURE,
    payload: error,
  };
}
