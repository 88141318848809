import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAILURE,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  GET_ACTIVATION_LINK_SUCCESS,
  ACCEPT_COOKIES,
  IS_TOKEN_ACTIVE_REQUEST,
  IS_TOKEN_ACTIVE_SUCCESS,
  REMOVE_EXPIRED_TOKEN,
  IS_TOKEN_ACTIVE_FAILURE,
  SAVE_LOGIN_DATA,
} from '../actions/authActions';

import { getToken, cookiesExist } from '../../shared/helpers/localStorage';

const authenticated = getToken();
const hasAcceptedCookies = cookiesExist();

const initialState = {
  authenticated,
  hasAcceptedCookies,
  errorMessage: '',
  loading: false,
  verifyUserResponse: {},
  forgotPasswordSuccess: '',
  newPasswordSuccess: '',
  accountVerificationLink: '',
  loginData: {},
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        errorMessage: '',
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
        loading: false,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: action.payload,
        loading: false,
        errorMessage: '',
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordSuccess: action.payload,
        loading: false,
        errorMessage: '',
      };
    case NEW_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case VERIFY_USER_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        verifyUserResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case GET_ACTIVATION_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        accountVerificationLink: action.payload,
        errorMessage: '',
      };
    case ACCEPT_COOKIES:
      localStorage.setItem('cookies_accepted', true);
      return {
        ...state,
        hasAcceptedCookies: true,
      };
    case IS_TOKEN_ACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case IS_TOKEN_ACTIVE_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        errorMessage: '',
      };
    case REMOVE_EXPIRED_TOKEN:
      return {
        ...state,
        authenticated: false,
        loading: false,
        errorMessage: '',
      };
    case IS_TOKEN_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SAVE_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    default:
      return state;
  }
}

export default authReducer;
