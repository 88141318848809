import moment from 'moment';

import {
  FETCH_CUSTOMER_SCREENS_REQUEST,
  FETCH_CUSTOMER_SCREENS_SUCCESS,
  FETCH_CUSTOMER_SCREENS_FAILURE,
  FETCH_FOR_CREATING_CAMPAIGN_REQUEST,
  FETCH_FOR_CREATING_CAMPAIGN_FAILURE,
  FETCH_AVAILABLE_SCREENS_SUCCESS,
  CREATE_CAMPAIGN_SUCCESS,
  FETCH_SINGLE_GROUP_REQUEST,
  FETCH_SINGLE_GROUP_SUCCESS,
  FETCH_SINGLE_GROUP_FAILURE,
  FETCH_CUSTOMER_GROUPS_REQUEST,
  FETCH_CUSTOMER_GROUPS_SUCCESS,
  FETCH_CUSTOMER_GROUPS_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  MANAGE_CAMPAIGN_NAME,
  MANAGE_CAMPAIGN_DESC,
  SELECT_CAMPAIGN_FOR_MANAGE,
  FETCH_FOR_MANAGING_CAMPAIGN_REQUEST,
  FETCH_FOR_MANAGING_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_SUCCESS,
  RESET_SELECTED_GROUP_FOR_MANAGE_GROUPS,
  RESET_NEW_GROUP_RESPONSE,
  FETCH_CUSTOMER_CAMPAIGNS_SUCCESS,
  CHANGE_CAMPAIGN_STATUS_FILTER,
  CHANGE_GROUP_SELECTOR_ON_MANAGE_CAMPAIGN,
  TOGGLE_TIME_PICKER,
  UPDATE_CAMPAIGN_NAME,
  UPDATE_CAMPAIGN_DESC,
  UPDATE_CAMPAIGN_START_DATE,
  UPDATE_CAMPAIGN_END_DATE,
  UPDATE_CAMPAIGN_START_TIME,
  UPDATE_CAMPAIGN_END_TIME,
  CHANGE_GROUP_SELECTOR_ON_CREATE_CAMPAIGN,
  SELECT_SCREEN_FOR_CREATE_CAMPAIGN,
  DESELECT_SCREEN_FOR_CREATE_CAMPAIGN,
  CHANGE_GROUP_SELECTOR_ON_MANAGE_DISPLAYS,
  CHANGE_DISPLAYS_FILTER,
  SELECT_SCREEN_FOR_MANAGE_DISPLAYS,
  DESELECT_SCREEN_FOR_MANAGE_DISPLAYS,
  ADD_OR_REMOVE_MEDIA_FOR_CREATE_CAMPAIGN,
  CHANGE_VIDEO_ORDER_IN_ARRAY,
  SELECTED_GROUP_FOR_ASSIGNING_SCREENS,
  SELECTED_GROUP_FOR_MANAGE_GROUPS,
  EDIT_GROUP_NAME,
  EDIT_GROUP_DESCRIPTION,
  EDIT_GROUP_ADD_DEFAULT_VIDEO,
  EDIT_GROUP_REMOVE_DEFAULT_VIDEO,
  REMOVE_DISPLAYS_FROM_GROUP,
  ASSIGN_SCREENS_TO_GROUP_SUCCESS,
  SELECTED_SCREEN_FOR_DETAILS,
  MANAGE_SCREEN_NAME,
  MANAGE_SCREEN_ADD_DEFAULT_VIDEO,
  MANAGE_SCREEN_REMOVE_DEFAULT_VIDEO,
  MANAGE_SCREEN_GROUP,
  MANAGE_SCREEN_STATUS,
  UPDATE_SCREEN_REQUEST,
  UPDATE_SCREEN_SUCCESS,
  UPDATE_SCREEN_FAILURE,
  RESET_UPDATE_SCREEN_RESPONSE,
  ADD_OR_REMOVE_LINKS_FOR_CREATE_CAMPAIGN,
} from '../actions/advertiseAppActions';

const initialState = {
  createCampaign: {
    name: '',
    description: '',
    duration: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().add(1, 'd').format('YYYY-MM-DD'),
    },
    showTimeToggler: false,
    startTime: '00:00',
    endTime: '23:59',
    selectedScreens: [],
    selectedGroup: '',
    screens: [],
    filteredScreens: [],
    videos: [],
    links: [],
    reach: [],
    ratio: [],
    createSuccessMessage: '',
  },
  apiError: '',
  manageCampaigns: {
    allCustomerCampaigns: [],
    filteredCustomerCampaigns: [],
    campaignStatusFilter: 'All',
    campaignGroupSelector: '',
    selectedCampaign: {},
    updateSuccessMessage: '',
  },
  manageDisplays: {
    allCustomerScreens: [],
    filteredCustomerScreens: [],
    screensFilter: 'All',
    selectedScreens: [],
    selectedGroupForAssigningScreens: {},
    selectedGroupOnManageGroups: {},
    groupInfo: {},
    screensToRemoveFromGroup: [],
    updateGroupSuccessMessage: '',
    assignedScreensToGroupSuccessMessage: '',
    updateScreenSuccessMessage: '',
    selectedScreen: {},
  },
  loading: false,
  customerGroups: [],
  onNewGroupResponse: {},
};

function customerScreensReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_SCREENS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMER_SCREENS_SUCCESS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          allCustomerScreens: action.payload,
          filteredCustomerScreens: action.payload,
        },
        loading: false,
        apiError: '',
      };
    case FETCH_CUSTOMER_SCREENS_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    case FETCH_FOR_CREATING_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        apiError: '',
        createCampaign: {
          ...state.createCampaign,
          createSuccessMessage: '',
        },
      };
    case FETCH_FOR_CREATING_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
        createCampaign: {
          ...state.createCampaign,
          createSuccessMessage: '',
        },
      };
    case FETCH_AVAILABLE_SCREENS_SUCCESS: {
      let filtered = [];
      if (state.customerGroups.length > 0) {
        filtered = action.payload.filter((item) => item.group.id === state.customerGroups[0].id);
      }
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          screens: action.payload,
          filteredScreens: filtered,
        },
        loading: false,
        apiError: '',
      };
    }
    case CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        apiError: '',
        createCampaign: {
          ...state.createCampaign,
          createSuccessMessage: action.payload.success,
        },
      };
    case SELECT_SCREEN_FOR_CREATE_CAMPAIGN:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          selectedScreens: [...state.createCampaign.selectedScreens, action.payload],
        },
      };
    case DESELECT_SCREEN_FOR_CREATE_CAMPAIGN:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          selectedScreens: state.createCampaign.selectedScreens.filter((screen) => screen.id !== action.payload.id),
        },
      };
    case CHANGE_GROUP_SELECTOR_ON_CREATE_CAMPAIGN: {
      let filtered = [];
      if (state.createCampaign.screens.length > 0) {
        filtered = state.createCampaign.screens.filter((item) => item.group.id === action.payload.id);
      }

      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          selectedScreens: [],
          filteredScreens: filtered,
          selectedGroup: action.payload.name,
        },
        loading: false,
        apiError: '',
      };
    }
    case ADD_OR_REMOVE_LINKS_FOR_CREATE_CAMPAIGN:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          links: action.payload,
        },
      };
    case ADD_OR_REMOVE_MEDIA_FOR_CREATE_CAMPAIGN:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          videos: action.payload,
        },
      };
    case CHANGE_VIDEO_ORDER_IN_ARRAY: {
      const indexFromWhere = action.payload.originalIndex;
      const indexToWhere = action.payload.indexToMove;
      const newVideosOrder = state.createCampaign.videos;
      const temporaryValue = state.createCampaign.videos[indexFromWhere];

      newVideosOrder[indexFromWhere] = newVideosOrder[indexToWhere];
      newVideosOrder[indexToWhere] = temporaryValue;

      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          videos: newVideosOrder,
        },
      };
    }
    case FETCH_SINGLE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLE_GROUP_SUCCESS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          groupInfo: action.payload,
          updateGroupSuccessMessage: '',
        },
        loading: false,
        apiError: '',
      };
    case FETCH_SINGLE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    case FETCH_CUSTOMER_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMER_GROUPS_SUCCESS:
      return {
        ...state,
        customerGroups: action.payload,
        createCampaign: {
          ...state.createCampaign,
          selectedGroup: action.payload[0].title,
        },
        loading: false,
        apiError: '',
      };
    case FETCH_CUSTOMER_GROUPS_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    case CREATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        onNewGroupResponse: action.payload,
        loading: false,
        apiError: '',
      };
    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    case UPDATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          updateGroupSuccessMessage: action.payload,
        },
        loading: false,
        apiError: '',
      };
    case ASSIGN_SCREENS_TO_GROUP_SUCCESS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          assignedScreensToGroupSuccessMessage: action.payload,
          selectedScreens: [],
        },
        loading: false,
        apiError: '',
      };
    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    case EDIT_GROUP_NAME:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          groupInfo: {
            ...state.manageDisplays.groupInfo,
            name: action.payload,
          },
        },
      };
    case EDIT_GROUP_DESCRIPTION:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          groupInfo: {
            ...state.manageDisplays.groupInfo,
            description: action.payload,
          },
        },
      };
    case EDIT_GROUP_ADD_DEFAULT_VIDEO:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          groupInfo: {
            ...state.manageDisplays.groupInfo,
            default_video: action.payload,
            videoUpdated: true,
          },
        },
      };
    case EDIT_GROUP_REMOVE_DEFAULT_VIDEO:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          groupInfo: {
            ...state.manageDisplays.groupInfo,
            default_video: {},
            videoUpdated: true,
          },
        },
      };
    case MANAGE_SCREEN_ADD_DEFAULT_VIDEO:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreen: {
            ...state.manageDisplays.selectedScreen,
            default_video: action.payload,
            videoUpdated: true,
          },
        },
      };
    case MANAGE_SCREEN_REMOVE_DEFAULT_VIDEO:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreen: {
            ...state.manageDisplays.selectedScreen,
            default_video: {},
            videoUpdated: true,
          },
        },
      };
    case REMOVE_DISPLAYS_FROM_GROUP:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          groupInfo: {
            ...state.manageDisplays.groupInfo,
            screens: state.manageDisplays.groupInfo.screens.filter((screen) => screen.id !== action.payload.id),
          },
          screensToRemoveFromGroup:
            [...state.manageDisplays.screensToRemoveFromGroup, action.payload.id],
        },
      };
    case MANAGE_CAMPAIGN_NAME:
      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          selectedCampaign: {
            ...state.manageCampaigns.selectedCampaign,
            name: action.payload,
          },
        },
      };
    case MANAGE_CAMPAIGN_DESC:
      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          selectedCampaign: {
            ...state.manageCampaigns.selectedCampaign,
            description: action.payload,
          },
        },
      };
    case SELECTED_SCREEN_FOR_DETAILS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreen: action.payload,
        },
      };
    case SELECT_CAMPAIGN_FOR_MANAGE:
      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          selectedCampaign: action.payload,
          updateSuccessMessage: '',
        },
        apiError: '',
        loading: false,
      };
    case UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          updateSuccessMessage: action.payload,
        },
      };
    }
    case RESET_NEW_GROUP_RESPONSE:
      return {
        ...state,
        loading: false,
        onNewGroupResponse: {},
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreens: [],
        },
      };
    case RESET_UPDATE_SCREEN_RESPONSE:
      return {
        ...state,
        loading: false,
        manageDisplays: {
          ...state.manageDisplays,
          updateScreenSuccessMessage: '',
          selectedScreen: {},
        },
      };
    case FETCH_FOR_MANAGING_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FOR_MANAGING_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    case FETCH_CUSTOMER_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          allCustomerCampaigns: action.payload,
          filteredCustomerCampaigns: action.payload,
        },
        loading: false,
        apiError: '',
      };
    }
    case CHANGE_GROUP_SELECTOR_ON_MANAGE_CAMPAIGN: {
      let filtered = [];
      filtered = state.manageCampaigns.allCustomerCampaigns.filter(
        (item) => item.screens[0].group.id === action.payload,
      );

      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          filteredCustomerCampaigns: filtered,
        },
        loading: false,
        apiError: '',
      };
    }
    case CHANGE_GROUP_SELECTOR_ON_MANAGE_DISPLAYS: {
      let filtered = [];
      filtered = state.manageDisplays.allCustomerScreens.filter(
        (item) => item.group.id === action.payload,
      );

      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          filteredCustomerScreens: filtered,
        },
        loading: false,
        apiError: '',
      };
    }
    case CHANGE_DISPLAYS_FILTER: {
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          filteredCustomerScreens: state.manageDisplays.allCustomerScreens,
          screensFilter: action.payload,
        },
        loading: false,
        apiError: '',
      };
    }
    case CHANGE_CAMPAIGN_STATUS_FILTER: {
      let filtered = [];

      if (action.payload === 'All') {
        filtered = state.manageCampaigns.allCustomerCampaigns;
      } else {
        filtered = state.manageCampaigns.allCustomerCampaigns.filter(
          (item) => item.global_status === action.payload,
        );
      }

      return {
        ...state,
        manageCampaigns: {
          ...state.manageCampaigns,
          filteredCustomerCampaigns: filtered,
          campaignStatusFilter: action.payload,
        },
        loading: false,
        apiError: '',
      };
    }
    case TOGGLE_TIME_PICKER:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          showTimeToggler: !state.createCampaign.showTimeToggler,
        },
      };
    case UPDATE_CAMPAIGN_NAME:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          name: action.payload,
        },
      };
    case UPDATE_CAMPAIGN_DESC:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          description: action.payload,
        },
      };
    case UPDATE_CAMPAIGN_START_DATE: {
      const currentStartDate = state.createCampaign.duration.start;

      let newStartDate = '';
      if (action.payload.type === 'day') {
        newStartDate = currentStartDate.replace(/.{0,2}$/, action.payload.value);
      } else if (action.payload.type === 'month') {
        newStartDate = currentStartDate.substring(0, 5) + action.payload.value + currentStartDate.substring(7);
      } else if (action.payload.type === 'year') {
        newStartDate = currentStartDate.replace(currentStartDate.substring(0, 4), action.payload.value);
      }

      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          duration: {
            ...state.createCampaign.duration,
            start: newStartDate,
          },
        },
      };
    }
    case UPDATE_CAMPAIGN_END_DATE: {
      const currentEndDate = state.createCampaign.duration.end;

      let newEndDate = '';
      if (action.payload.type === 'day') {
        newEndDate = currentEndDate.replace(/.{0,2}$/, action.payload.value);
      } else if (action.payload.type === 'month') {
        newEndDate = currentEndDate.substring(0, 5) + action.payload.value + currentEndDate.substring(7);
      } else if (action.payload.type === 'year') {
        newEndDate = currentEndDate.replace(currentEndDate.substring(0, 4), action.payload.value);
      }

      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          duration: {
            ...state.createCampaign.duration,
            end: newEndDate,
          },
        },
      };
    }
    case UPDATE_CAMPAIGN_START_TIME: {
      const currentStartTime = state.createCampaign.startTime;

      let newStartTime = '';
      if (action.payload.type === 'minutes') {
        newStartTime = currentStartTime.replace(/.{0,2}$/, action.payload.value);
      } else if (action.payload.type === 'hours') {
        newStartTime = currentStartTime.replace(currentStartTime.substring(0, 2), action.payload.value);
      }

      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          startTime: newStartTime,
        },
      };
    }
    case UPDATE_CAMPAIGN_END_TIME: {
      const currentEndTime = state.createCampaign.endTime;

      let newEndTime = '';
      if (action.payload.type === 'minutes') {
        newEndTime = currentEndTime.replace(/.{0,2}$/, action.payload.value);
      } else if (action.payload.type === 'hours') {
        newEndTime = currentEndTime.replace(currentEndTime.substring(0, 2), action.payload.value);
      }

      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          endTime: newEndTime,
        },
      };
    }

    case SELECT_SCREEN_FOR_MANAGE_DISPLAYS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreens: [...state.manageDisplays.selectedScreens, action.payload],
          assignedScreensToGroupSuccessMessage: '',
          updateScreenSuccessMessage: '',
        },
      };
    case DESELECT_SCREEN_FOR_MANAGE_DISPLAYS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreens: state.manageDisplays.selectedScreens.filter((screen) => screen.id !== action.payload.id),
          assignedScreensToGroupSuccessMessage: '',
          updateScreenSuccessMessage: '',
        },
      };
    case SELECTED_GROUP_FOR_ASSIGNING_SCREENS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedGroupForAssigningScreens: action.payload,
        },
      };
    case SELECTED_GROUP_FOR_MANAGE_GROUPS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedGroupOnManageGroups: action.payload,
        },
      };
    case RESET_SELECTED_GROUP_FOR_MANAGE_GROUPS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedGroupOnManageGroups: {},
          updateGroupSuccessMessage: '',
        },
      };
    case MANAGE_SCREEN_NAME:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreen: {
            ...state.manageDisplays.selectedScreen,
            advertise_nickname: action.payload,
          },
        },
      };
    case MANAGE_SCREEN_GROUP:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreen: {
            ...state.manageDisplays.selectedScreen,
            group: action.payload,
            groupUpdated: true,
          },
        },
      };
    case MANAGE_SCREEN_STATUS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          selectedScreen: {
            ...state.manageDisplays.selectedScreen,
            status: action.payload,
          },
        },
      };
    case UPDATE_SCREEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SCREEN_SUCCESS:
      return {
        ...state,
        manageDisplays: {
          ...state.manageDisplays,
          updateScreenSuccessMessage: action.payload,
        },
        loading: false,
        apiError: '',
      };
    case UPDATE_SCREEN_FAILURE:
      return {
        ...state,
        loading: false,
        apiError: action.payload,
      };
    default:
      return state;
  }
}

export default customerScreensReducer;
