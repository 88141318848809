/* eslint-disable consistent-return */
import moment from 'moment';
import {
  SELECT_WASTE_APP_ROUTE,
  DESELECT_WASTE_ROUTES,
  FETCH_CURRENT_CONFIG_REQUEST,
  FETCH_CURRENT_CONFIG_FAILURE,
  SET_NEW_CONFIG_REQUEST,
  SET_NEW_CONFIG_FAILURE,
  SELECTED_PRODUCT_FOR_WASTE,
  SELECT_SENSOR_FOR_WASTE_APP,
  DESELECT_SENSOR_FOR_WASTE_APP,
  FETCH_SENSORS_REQUEST_WA,
  FETCH_SENSORS_SUCCESS_WA,
  FETCH_SENSORS_FAILURE_WA,
  SELECT_DATE_FOR_WASTE_APP,
  FETCH_MULTIPLE_SENSORS_SUCCESS_WA,
  FETCH_SENSORS_MONTH_SUCCESS_WA,
  FETCH_SENSORS_WEEK_SUCCESS_WA,
  FETCH_SENSORS_YESTERDAY_SUCCESS_WA,
  FETCH_SENSORS_CUSTOM_SUCCESS_WA,
  FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS_WA,
  FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS_WA,
  FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS_WA,
  FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS_WA,
  REMOVE_SENSOR_DATA_WA,
  RESET_SELECTED_SENSOR_FOR_WASTE_APP,
  RESET_ONE_PRODUCT_DATA_STATE_WA,
  RESET_MULTIPLE_PRODUCTS_DATA_STATE_WA,
} from '../actions/wasteAppActions';

const now = moment().format('DD.MM.YYYY');
const initialState = {
  selectedRoute: '',
  onGeoDataUpdate: '',
  onWifiHomepageUpdate: {},
  wifiCurrentConfig: [],
  heatingCurrentConfig: [],
  ledCurrentConfig: [],
  errorMessage: '',
  selectedControlBar: 'schedule',
  powerButtonCurrentConfig: [],
  selectedProductsForPowerChange: [],
  selectedProductForManage: {},
  onPowerStateUpdate: {},
  onNewConfigUpdate: '',
  onLedConfigUpdate: '',

  selectedSensors: [],
  sensorsData: [],
  sensorsDataYesterday: [],
  sensorsDataWeek: [],
  sensorsDataMonth: [],
  sensorsDataCustom: [],
  multipleSensorData: [],
  multipleSensorDataYesterday: [],
  multipleSensorDataWeek: [],
  multipleSensorDataMonth: [],
  multipleSensorDataCustom: [],
  selectedTimePeriod: {
    startDate: now,
    endDate: now,
    title: 'today',
  },
};

function wasteAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_WASTE_APP_ROUTE:
      return {
        ...state,
        selectedRoute: action.payload,
      };
    case DESELECT_WASTE_ROUTES:
      return {
        ...state,
        selectedRoute: '',
      };

    case FETCH_CURRENT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        wifiCurrentConfig: [],
        heatingCurrentConfig: [],
      };
    case FETCH_CURRENT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        wifiCurrentConfig: [],
        heatingCurrentConfig: [],
      };
    case SELECTED_PRODUCT_FOR_WASTE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        selectedProductForManage: action.payload,
      };
    case SET_NEW_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onNewConfigUpdate: '',
      };
    case SET_NEW_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onNewConfigUpdate: '',
      };
    case SELECT_SENSOR_FOR_WASTE_APP:
      return {
        ...state,
        selectedSensors: [...state.selectedSensors, action.payload],
        loading: false,
      };
    case DESELECT_SENSOR_FOR_WASTE_APP:
      return {
        ...state,
        selectedSensors: state.selectedSensors.filter((item) => item !== action.payload),
      };
    case RESET_SELECTED_SENSOR_FOR_WASTE_APP:
      return {
        ...state,
        selectedSensors: [],
        loading: false,
      };
    case RESET_ONE_PRODUCT_DATA_STATE_WA:
      return {
        ...state,
        sensorsData: [],
        sensorsDataYesterday: [],
        sensorsDataWeek: [],
        sensorsDataMonth: [],
        sensorsDataCustom: [],
        loading: false,
      };
    case RESET_MULTIPLE_PRODUCTS_DATA_STATE_WA:
      return {
        ...state,
        multipleSensorData: [],
        multipleSensorDataYesterday: [],
        multipleSensorDataWeek: [],
        multipleSensorDataMonth: [],
        multipleSensorDataCustom: [],
        loading: false,
      };
    case FETCH_SENSORS_REQUEST_WA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SENSORS_SUCCESS_WA:
      if (!state.sensorsData.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          multipleSensorData: [],
          sensorsData: [...state.sensorsData, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
        multipleSensorData: [],
        loading: false,
      };
    case FETCH_MULTIPLE_SENSORS_SUCCESS_WA:
      if (!state.multipleSensorData.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          sensorsData: [],
          multipleSensorData: [...state.multipleSensorData, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
        sensorsData: [],
        multipleSensorData: [action.payload],
        loading: false,
      };
    case FETCH_SENSORS_FAILURE_WA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_DATE_FOR_WASTE_APP:
      return {
        ...state,
        selectedTimePeriod: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          title: action.payload.title,
        },
      };
    case FETCH_SENSORS_MONTH_SUCCESS_WA:
      if (!state.sensorsDataMonth.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataMonth: [...state.sensorsDataMonth, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_SENSORS_CUSTOM_SUCCESS_WA: {
      const currentState = state.sensorsDataCustom.filter((item) => item.module_id !== action.payload.module_id);
      return {
        ...state,
        sensorsDataCustom: [...currentState, action.payload],
        loading: false,
      };
    }
    case FETCH_SENSORS_WEEK_SUCCESS_WA:
      if (!state.sensorsDataWeek.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataWeek: [...state.sensorsDataWeek, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_SENSORS_YESTERDAY_SUCCESS_WA:
      if (!state.sensorsDataYesterday.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataYesterday: [...state.sensorsDataYesterday, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS_WA:
      if (!state.multipleSensorDataYesterday.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          multipleSensorDataYesterday: [...state.multipleSensorDataYesterday, action.payload],
          loading: false,
        };
      }
      break;
    case FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS_WA:
      if (!state.multipleSensorDataWeek.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          multipleSensorDataWeek: [...state.multipleSensorDataWeek, action.payload],
          loading: false,
        };
      }
      break;
    case FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS_WA:
      if (!state.multipleSensorDataMonth.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          multipleSensorDataMonth: [...state.multipleSensorDataMonth, action.payload],
          loading: false,
        };
      }
      break;
    case FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS_WA:
      return {
        ...state,
        multipleSensorDataCustom: [action.payload],
        loading: false,
      };
    case REMOVE_SENSOR_DATA_WA:
      return {
        ...state,
        sensorsData: state.sensorsData.filter((item) => item.module_id !== action.payload),
        sensorsDataYesterday: state.sensorsDataYesterday.filter((item) => item.module_id !== action.payload),
        sensorsDataWeek: state.sensorsDataWeek.filter((item) => item.module_id !== action.payload),
        sensorsDataMonth: state.sensorsDataMonth.filter((item) => item.module_id !== action.payload),
        sensorsDataCustom: state.sensorsDataCustom.filter((item) => item.module_id !== action.payload),
        multipleSensorData: state.multipleSensorData.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataYesterday:
          state.multipleSensorDataYesterday.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataWeek: state.multipleSensorDataWeek.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataMonth: state.multipleSensorDataMonth.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataCustom: state.multipleSensorDataCustom.filter((item) => item[0].module_id !== action.payload),
        loading: false,
        selectedTimePeriod: {
          startDate: now,
          endDate: now,
          title: 'today',
        },
      };
    default:
      return state;
  }
}

export default wasteAppReducer;
