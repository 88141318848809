import {
  SELECT_MANAGE_APP_ROUTE,
  DESELECT_MANAGE_ROUTES,
  UPDATE_GEO_DATA_REQUEST,
  UPDATE_GEO_DATA_SUCCESS,
  UPDATE_GEO_DATA_FAILURE,
  FETCH_CURRENT_CONFIG_REQUEST,
  FETCH_CURRENT_CONFIG_FAILURE,
  FETCH_WIFI_CONFIG_SUCCESS,
  FETCH_LED_CONFIG_SUCCESS,
  UPDATE_WIFI_SCHEDULE,
  SELECT_MANAGE_APP_MODULE_CONTROL_BAR,
  FETCH_POWER_BUTTON_CONFIG_SUCCESS,
  SET_WIFI_HOMEPAGE_REQUEST,
  SET_WIFI_HOMEPAGE_SUCCESS,
  SET_WIFI_HOMEPAGE_FAILURE,
  REQUEST_GEO_LOCATING_REQUEST,
  REQUEST_GEO_LOCATING_FAILURE,
  ADD_PRODUCTS_FOR_POWER_BUTTON_CONFIG,
  UPDATE_POWER_STATE_REQUEST,
  UPDATE_POWER_STATE_SUCCESS,
  UPDATE_POWER_STATE_FAILURE,
  SET_NEW_CONFIG_REQUEST,
  SET_NEW_WIFI_CONFIG_SUCCESS,
  SET_NEW_CONFIG_FAILURE,
  UPDATE_LED_VALUE_FOR_SPRING,
  UPDATE_LED_VALUE_FOR_SUMMER,
  UPDATE_LED_VALUE_FOR_FALL,
  UPDATE_LED_VALUE_FOR_WINTER,
  SET_NEW_LED_CONFIG_SUCCESS,
  FETCH_HEATING_CONFIG_SUCCESS,
  SET_NEW_HEATING_CONFIG_SUCCESS,
  UPDATE_HEATING_SCHEDULE,
  UPDATE_TEMPERATURE,
  SELECTED_PRODUCT_FOR_MANAGE,
  MANAGE_LATITUDE,
  MANAGE_LOCATION,
  MANAGE_LONGITUDE,
  RESET_GEO_DATA_RESPONSE,
  MANAGE_LANDING_PAGE,
  RESET_WIFI_RESPONSE,
  UPDATE_TERRA_LOCK_SCHEDULE,
  FETCH_TERRA_LOCK_CONFIG_SUCCESS,
  SET_NEW_TERRA_LOCK_CONFIG_SUCCESS,
} from '../actions/manageAppActions';

const initialState = {
  selectedRoute: '',
  onGeoDataUpdate: '',
  onWifiHomepageUpdate: {},
  wifiCurrentConfig: [],
  heatingCurrentConfig: [],
  ledCurrentConfig: [],
  errorMessage: '',
  selectedControlBar: 'schedule',
  powerButtonCurrentConfig: [],
  selectedProductsForPowerChange: [],
  selectedProductForManage: {},
  onPowerStateUpdate: {},
  onNewConfigUpdate: '',
  onLedConfigUpdate: '',
  terraLockCurrentConfig: [],
};

function manageAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_MANAGE_APP_ROUTE:
      return {
        ...state,
        selectedRoute: action.payload,
      };
    case DESELECT_MANAGE_ROUTES:
      return {
        ...state,
        selectedRoute: '',
      };
    case UPDATE_GEO_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onGeoDataUpdate: '',
      };
    case UPDATE_GEO_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onGeoDataUpdate: '',
      };
    case UPDATE_GEO_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onGeoDataUpdate: action.payload,
      };
    case RESET_GEO_DATA_RESPONSE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onGeoDataUpdate: '',
      };
    case FETCH_CURRENT_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        wifiCurrentConfig: [],
        heatingCurrentConfig: [],
        terraLockCurrentConfig: [],
      };
    case FETCH_WIFI_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        wifiCurrentConfig: action.payload,
      };
    case FETCH_TERRA_LOCK_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        terraLockCurrentConfig: action.payload,
      };
    case FETCH_HEATING_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: action.payload,
      };
    case FETCH_LED_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: action.payload,
      };
    case FETCH_CURRENT_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        wifiCurrentConfig: [],
        heatingCurrentConfig: [],
        terraLockCurrentConfig: [],
      };
    case SELECT_MANAGE_APP_MODULE_CONTROL_BAR:
      return {
        ...state,
        selectedControlBar: action.payload,
      };
    case FETCH_POWER_BUTTON_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        powerButtonCurrentConfig: action.payload,
      };
    case SET_WIFI_HOMEPAGE_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onWifiHomepageUpdate: {},
      };
    case SET_WIFI_HOMEPAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onWifiHomepageUpdate: action.payload,
      };
    case SET_WIFI_HOMEPAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onWifiHomepageUpdate: {},
      };
    case REQUEST_GEO_LOCATING_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case REQUEST_GEO_LOCATING_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case ADD_PRODUCTS_FOR_POWER_BUTTON_CONFIG: {
      const currentState = state.selectedProductsForPowerChange.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        selectedProductsForPowerChange: [...currentState, action.payload],
      };
    }
    case UPDATE_POWER_STATE_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onPowerStateUpdate: {},
      };
    case UPDATE_POWER_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onPowerStateUpdate: action.payload,
        selectedProductsForPowerChange: [],
      };
    case UPDATE_POWER_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onPowerStateUpdate: {},
        selectedProductsForPowerChange: [],
      };
    case SELECTED_PRODUCT_FOR_MANAGE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        selectedProductForManage: action.payload,
      };
    case UPDATE_WIFI_SCHEDULE: {
      const wifiConfig = state.wifiCurrentConfig;
      const wifiStartTime = wifiConfig[0].module.config.find((element) => element.var === 'B');
      const wifiEndTime = wifiConfig[0].module.config.find((element) => element.var === 'E');

      if (action.payload.variable === 'B') { // variable 'B' start time
        if (action.payload.type === 'hours') wifiStartTime.value = wifiStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') wifiStartTime.value = wifiStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // variable 'E' end time
        if (action.payload.type === 'hours') wifiEndTime.value = wifiEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') wifiEndTime.value = wifiEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        wifiCurrentConfig: wifiConfig,
      };
    }
    case UPDATE_TERRA_LOCK_SCHEDULE: {
      const terraLock = state.terraLockCurrentConfig;
      const terraLockStartTime = terraLock[0].module.config.find((element) => element.var === 'B');
      const terraLockEndTime = terraLock[0].module.config.find((element) => element.var === 'E');

      if (action.payload.variable === 'B') { // variable 'B' start time
        if (action.payload.type === 'hours') terraLockStartTime.value = terraLockStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') terraLockStartTime.value = terraLockStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // variable 'E' end time
        if (action.payload.type === 'hours') terraLockEndTime.value = terraLockEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') terraLockEndTime.value = terraLockEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        terraLockCurrentConfig: terraLock,
      };
    }
    case UPDATE_HEATING_SCHEDULE: {
      const heatingConfig = state.heatingCurrentConfig;
      const heatingStartTime = heatingConfig[0].module.config.find((element) => element.var === 'B');
      const heatingEndTime = heatingConfig[0].module.config.find((element) => element.var === 'E');

      if (action.payload.variable === 'B') { // variable 'B' start time
        if (action.payload.type === 'hours') heatingStartTime.value = heatingStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') heatingStartTime.value = heatingStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // variable 'E' end time
        if (action.payload.type === 'hours') heatingEndTime.value = heatingEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') heatingEndTime.value = heatingEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: heatingConfig,
      };
    }
    case UPDATE_TEMPERATURE: {
      const heatingConfig = state.heatingCurrentConfig;
      const heatingTemperature = heatingConfig[0].module.config.find((element) => element.var === 'V');

      if (action.payload) heatingTemperature.value = action.payload;

      return {
        ...state,
        loading: false,
        errorMessage: '',
        heatingCurrentConfig: heatingConfig,
      };
    }
    case SET_NEW_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        onNewConfigUpdate: '',
      };
    case SET_NEW_WIFI_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: action.payload,
      };
    case SET_NEW_TERRA_LOCK_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: action.payload,
      };
    case SET_NEW_HEATING_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: action.payload,
      };
    case SET_NEW_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        onNewConfigUpdate: '',
      };
    case RESET_WIFI_RESPONSE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onNewConfigUpdate: '',
      };
    case UPDATE_LED_VALUE_FOR_SPRING: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[3].config[0];
      const ledEndTime = newConfig[0].seasons[3].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case UPDATE_LED_VALUE_FOR_SUMMER: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[0].config[0];
      const ledEndTime = newConfig[0].seasons[0].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case UPDATE_LED_VALUE_FOR_FALL: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[1].config[0];
      const ledEndTime = newConfig[0].seasons[1].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case UPDATE_LED_VALUE_FOR_WINTER: {
      const newConfig = state.ledCurrentConfig;
      const ledStartTime = newConfig[0].seasons[2].config[0];
      const ledEndTime = newConfig[0].seasons[2].config[1];

      if (action.payload.variable === 'B') { // start time
        if (action.payload.type === 'hours') ledStartTime.value = ledStartTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledStartTime.value = ledStartTime.value.replace(/..$/, action.payload.value);
      } else if (action.payload.variable === 'E') { // end time
        if (action.payload.type === 'hours') ledEndTime.value = ledEndTime.value.replace(/^.{2}/g, action.payload.value);
        if (action.payload.type === 'minutes') ledEndTime.value = ledEndTime.value.replace(/..$/, action.payload.value);
      }

      return {
        ...state,
        loading: false,
        errorMessage: '',
        ledCurrentConfig: newConfig,
      };
    }
    case SET_NEW_LED_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        onLedConfigUpdate: action.payload,
      };
    case MANAGE_LATITUDE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        selectedProductForManage: {
          ...state.selectedProductForManage,
          lattitude: action.payload,
        },
      };
    case MANAGE_LOCATION:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        selectedProductForManage: {
          ...state.selectedProductForManage,
          location: action.payload,
        },
      };
    case MANAGE_LANDING_PAGE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        selectedProductForManage: {
          ...state.selectedProductForManage,
          homepage: action.payload,
        },
      };
    case MANAGE_LONGITUDE:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        selectedProductForManage: {
          ...state.selectedProductForManage,
          longitude: action.payload,
        },
      };
    default:
      return state;
  }
}

export default manageAppReducer;
