export const GET_PUBLIC_CAMPAIGN_INFO_SUCCESS = 'GET_PUBLIC_CAMPAIGN_INFO_SUCCESS';
export const GET_PUBLIC_CAMPAIGN_INFO_REQUEST = 'GET_PUBLIC_CAMPAIGN_INFO_REQUEST';
export const GET_PUBLIC_CAMPAIGN_INFO_FAILURE = 'GET_PUBLIC_CAMPAIGN_INFO_FAILURE';

export const GET_PUBLIC_AIR_QUALITY_INDEX_SUCCESS = 'GET_PUBLIC_AIR_QUALITY_INDEX_SUCCESS';
export const GET_PUBLIC_AIR_QUALITY_INDEX_REQUEST = 'GET_PUBLIC_AIR_QUALITY_INDEX_REQUEST';
export const GET_PUBLIC_AIR_QUALITY_INDEX_FAILURE = 'GET_PUBLIC_AIR_QUALITY_INDEX_FAILURE';
export const SELECT_DATE_FOR_AERYS = 'SELECT_DATE_FOR_AERYS';

export function getCampaignInfoForPublicLinkSuccess(data) {
  return {
    type: GET_PUBLIC_CAMPAIGN_INFO_SUCCESS,
    payload: data,
  };
}
export function getCampaignInfoForPublicLinkFailure(error) {
  return {
    type: GET_PUBLIC_CAMPAIGN_INFO_FAILURE,
    payload: error,
  };
}
export function getCampaignInfoForPublicLinkRequest() {
  return {
    type: GET_PUBLIC_CAMPAIGN_INFO_REQUEST,
  };
}

export function getPublicAirQualityIndexSuccess(data) {
  return {
    type: GET_PUBLIC_AIR_QUALITY_INDEX_SUCCESS,
    payload: data,
  };
}

export function getPublicAirQualityIndexFailure(error) {
  return {
    type: GET_PUBLIC_AIR_QUALITY_INDEX_FAILURE,
    payload: error,
  };
}

export function getPublicAirQualityIndexRequest() {
  return {
    type: GET_PUBLIC_AIR_QUALITY_INDEX_REQUEST,
  };
}

export function selectDate(date) {
  return {
    type: SELECT_DATE_FOR_AERYS,
    payload: date,
  };
}
