export const FETCH_CUSTOMER_SCREENS_REQUEST = 'FETCH_CUSTOMER_SCREENS_REQUEST';
export const FETCH_CUSTOMER_SCREENS_SUCCESS = 'FETCH_CUSTOMER_SCREENS_SUCCESS';
export const FETCH_CUSTOMER_SCREENS_FAILURE = 'FETCH_CUSTOMER_SCREENS_FAILURE';
export const FETCH_SINGLE_GROUP_REQUEST = 'FETCH_SINGLE_GROUP_REQUEST';
export const FETCH_SINGLE_GROUP_SUCCESS = 'FETCH_SINGLE_GROUP_SUCCESS';
export const FETCH_SINGLE_GROUP_FAILURE = 'FETCH_SINGLE_GROUP_FAILURE';
export const FETCH_CUSTOMER_GROUPS_REQUEST = 'FETCH_CUSTOMER_GROUPS_REQUEST';
export const FETCH_CUSTOMER_GROUPS_SUCCESS = 'FETCH_CUSTOMER_GROUPS_SUCCESS';
export const FETCH_CUSTOMER_GROUPS_FAILURE = 'FETCH_CUSTOMER_GROUPS_FAILURE';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const UPDATE_SCREEN_REQUEST = 'UPDATE_SCREEN_REQUEST';
export const UPDATE_SCREEN_SUCCESS = 'UPDATE_SCREEN_SUCCESS';
export const UPDATE_SCREEN_FAILURE = 'UPDATE_SCREEN_FAILURE';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const EDIT_GROUP_NAME = 'EDIT_GROUP_NAME';
export const EDIT_GROUP_DESCRIPTION = 'EDIT_GROUP_DESCRIPTION';
export const EDIT_GROUP_REMOVE_DEFAULT_VIDEO = 'EDIT_GROUP_REMOVE_DEFAULT_VIDEO';
export const EDIT_GROUP_ADD_DEFAULT_VIDEO = 'EDIT_GROUP_ADD_DEFAULT_VIDEO';
export const REMOVE_DISPLAYS_FROM_GROUP = 'REMOVE_DISPLAYS_FROM_GROUP';
export const MANAGE_CAMPAIGN_NAME = 'MANAGE_CAMPAIGN_NAME';
export const MANAGE_CAMPAIGN_DESC = 'MANAGE_CAMPAIGN_DESC';
export const SELECT_CAMPAIGN_FOR_MANAGE = 'SELECT_CAMPAIGN_FOR_MANAGE';
export const ASSIGN_SCREENS_TO_GROUP_REQUEST = 'ASSIGN_SCREENS_TO_GROUP_REQUEST';
export const ASSIGN_SCREENS_TO_GROUP_SUCCESS = 'ASSIGN_SCREENS_TO_GROUP_SUCCESS';
export const ASSIGN_SCREENS_TO_GROUP_FAILURE = 'ASSIGN_SCREENS_TO_GROUP_FAILURE';
export const SELECTED_GROUP_FOR_ASSIGNING_SCREENS = 'SELECTED_GROUP_FOR_ASSIGNING_SCREENS';
export const SELECTED_GROUP_FOR_MANAGE_GROUPS = 'SELECTED_GROUP_FOR_MANAGE_GROUPS';
export const RESET_SELECTED_GROUP_FOR_MANAGE_GROUPS = 'RESET_SELECTED_GROUP_FOR_MANAGE_GROUPS';
export const RESET_UPDATE_SCREEN_RESPONSE = 'RESET_UPDATE_SCREEN_RESPONSE';
export const CHANGE_GROUP_SELECTOR_ON_MANAGE_DISPLAYS = 'CHANGE_GROUP_SELECTOR_ON_MANAGE_DISPLAYS';
export const RESET_NEW_GROUP_RESPONSE = 'RESET_NEW_GROUP_RESPONSE';
export const FETCH_FOR_MANAGING_CAMPAIGN_REQUEST = 'FETCH_FOR_MANAGING_CAMPAIGN_REQUEST';
export const FETCH_FOR_MANAGING_CAMPAIGN_FAILURE = 'FETCH_FOR_MANAGING_CAMPAIGN_FAILURE';
export const FETCH_CUSTOMER_CAMPAIGNS_SUCCESS = 'FETCH_CUSTOMER_CAMPAIGNS_SUCCESS';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_VIDEOS = 'UPDATE_CAMPAIGN_VIDEOS';
export const CHANGE_CAMPAIGN_STATUS_FILTER = 'CHANGE_CAMPAIGN_STATUS_FILTER';
export const CHANGE_GROUP_SELECTOR_ON_MANAGE_CAMPAIGN = 'CHANGE_GROUP_SELECTOR_ON_MANAGE_CAMPAIGN';
export const CHANGE_DISPLAYS_FILTER = 'CHANGE_DISPLAYS_FILTER';
export const SELECTED_SCREEN_FOR_DETAILS = 'SELECTED_SCREEN_FOR_DETAILS';
export const MANAGE_SCREEN_NAME = 'MANAGE_SCREEN_NAME';
export const MANAGE_SCREEN_ADD_DEFAULT_VIDEO = 'MANAGE_SCREEN_ADD_DEFAULT_VIDEO';
export const MANAGE_SCREEN_REMOVE_DEFAULT_VIDEO = 'MANAGE_SCREEN_REMOVE_DEFAULT_VIDEO';
export const MANAGE_SCREEN_GROUP = 'MANAGE_SCREEN_GROUP';
export const MANAGE_SCREEN_STATUS = 'MANAGE_SCREEN_STATUS';

export const TOGGLE_TIME_PICKER = 'TOGGLE_TIME_PICKER';
export const UPDATE_CAMPAIGN_NAME = 'UPDATE_CAMPAIGN_NAME';
export const UPDATE_CAMPAIGN_DESC = 'UPDATE_CAMPAIGN_DESC';
export const UPDATE_CAMPAIGN_START_DATE = 'UPDATE_CAMPAIGN_START_DATE';
export const UPDATE_CAMPAIGN_END_DATE = 'UPDATE_CAMPAIGN_END_DATE';
export const UPDATE_CAMPAIGN_START_TIME = 'UPDATE_CAMPAIGN_START_TIME';
export const UPDATE_CAMPAIGN_END_TIME = 'UPDATE_CAMPAIGN_END_TIME';
export const FETCH_FOR_CREATING_CAMPAIGN_REQUEST = 'FETCH_FOR_CREATING_CAMPAIGN_REQUEST';
export const FETCH_FOR_CREATING_CAMPAIGN_FAILURE = 'FETCH_FOR_CREATING_CAMPAIGN_FAILURE';
export const FETCH_AVAILABLE_SCREENS_SUCCESS = 'FETCH_AVAILABLE_SCREENS_SUCCESS';
export const FILTER_CUSTOMER_SCREENS_BY_GROUP = 'FILTER_CUSTOMER_SCREENS_BY_GROUP';
export const CHANGE_GROUP_SELECTOR_ON_CREATE_CAMPAIGN = 'CHANGE_GROUP_SELECTOR_ON_CREATE_CAMPAIGN';
export const SELECT_SCREEN_FOR_CREATE_CAMPAIGN = 'SELECT_SCREEN_FOR_CREATE_CAMPAIGN';
export const DESELECT_SCREEN_FOR_CREATE_CAMPAIGN = 'DESELECT_SCREEN_FOR_CREATE_CAMPAIGN';
export const ADD_OR_REMOVE_MEDIA_FOR_CREATE_CAMPAIGN = 'ADD_OR_REMOVE_MEDIA_FOR_CREATE_CAMPAIGN';
export const CHANGE_VIDEO_ORDER_IN_ARRAY = 'CHANGE_VIDEO_ORDER_IN_ARRAY';
export const SELECT_SCREEN_FOR_MANAGE_DISPLAYS = 'SELECT_SCREEN_FOR_MANAGE_DISPLAYS';
export const DESELECT_SCREEN_FOR_MANAGE_DISPLAYS = 'DESELECT_SCREEN_FOR_MANAGE_DISPLAYS';
export const ADD_OR_REMOVE_LINKS_FOR_CREATE_CAMPAIGN = 'ADD_OR_REMOVE_LINKS_FOR_CREATE_CAMPAIGN';

export function fetchCustomerScreensRequest() {
  return {
    type: FETCH_CUSTOMER_SCREENS_REQUEST,
  };
}

export function fetchCustomerScreensSuccess(data) {
  return {
    type: FETCH_CUSTOMER_SCREENS_SUCCESS,
    payload: data,
  };
}

export function fetchCustomerScreensFailure(error) {
  return {
    type: FETCH_CUSTOMER_SCREENS_FAILURE,
    payload: error,
  };
}

export function fetchForCreatingCampaignRequest() {
  return {
    type: FETCH_FOR_CREATING_CAMPAIGN_REQUEST,
  };
}

export function fetchForCreatingCampaignFailure(error) {
  return {
    type: FETCH_FOR_CREATING_CAMPAIGN_FAILURE,
    payload: error,
  };
}

export function fetchAvailableScreensSuccess(data) {
  return {
    type: FETCH_AVAILABLE_SCREENS_SUCCESS,
    payload: data,
  };
}

export function changeCampaignStatusFilter(status) {
  return {
    type: CHANGE_CAMPAIGN_STATUS_FILTER,
    payload: status,
  };
}

export function changeGroupSelectorOnManageCampaign(id) {
  return {
    type: CHANGE_GROUP_SELECTOR_ON_MANAGE_CAMPAIGN,
    payload: id,
  };
}

export function changeGroupSelectorOnCreateCampaign(group) {
  return {
    type: CHANGE_GROUP_SELECTOR_ON_CREATE_CAMPAIGN,
    payload: { id: group.id, name: group.name },
  };
}

export function changeGroupSelectorOnManageDisplays(id) {
  return {
    type: CHANGE_GROUP_SELECTOR_ON_MANAGE_DISPLAYS,
    payload: id,
  };
}

export function changeDisplaysFilter(status) {
  return {
    type: CHANGE_DISPLAYS_FILTER,
    payload: status,
  };
}

export function fetchSingleGroupRequest() {
  return {
    type: FETCH_SINGLE_GROUP_REQUEST,
  };
}

export function fetchSingleGroupSuccess(data) {
  return {
    type: FETCH_SINGLE_GROUP_SUCCESS,
    payload: data,
  };
}

export function fetchSingleGroupFailure(error) {
  return {
    type: FETCH_SINGLE_GROUP_FAILURE,
    payload: error,
  };
}

export function fetchCustomerGroupsRequest() {
  return {
    type: FETCH_CUSTOMER_GROUPS_REQUEST,
  };
}

export function fetchCustomerGroupsSuccess(data) {
  return {
    type: FETCH_CUSTOMER_GROUPS_SUCCESS,
    payload: data,
  };
}

export function fetchCustomerGroupsFailure(error) {
  return {
    type: FETCH_CUSTOMER_GROUPS_FAILURE,
    payload: error,
  };
}

export function createGroupRequest() {
  return {
    type: CREATE_GROUP_REQUEST,
  };
}

export function createGroupSuccess(data) {
  return {
    type: CREATE_GROUP_SUCCESS,
    payload: data,
  };
}

export function createGroupFailure(error) {
  return {
    type: CREATE_GROUP_FAILURE,
    payload: error,
  };
}

export function createCampaignSuccess(data) {
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    payload: data,
  };
}

export function updateGroupRequest() {
  return {
    type: UPDATE_GROUP_REQUEST,
  };
}

export function updateGroupSuccess(data) {
  return {
    type: UPDATE_GROUP_SUCCESS,
    payload: data,
  };
}

export function updateGroupFailure(error) {
  return {
    type: UPDATE_GROUP_FAILURE,
    payload: error,
  };
}

export function editGroupName(name) {
  return {
    type: EDIT_GROUP_NAME,
    payload: name,
  };
}

export function editGroupDescription(description) {
  return {
    type: EDIT_GROUP_DESCRIPTION,
    payload: description,
  };
}

export function editGroupAddDefaultVideo(video) {
  return {
    type: EDIT_GROUP_ADD_DEFAULT_VIDEO,
    payload: video,
  };
}

export function editGroupRemoveDefaultVideo(screen) {
  return {
    type: EDIT_GROUP_REMOVE_DEFAULT_VIDEO,
    payload: screen,
  };
}

export function editGroupDisplays(screen) {
  return {
    type: REMOVE_DISPLAYS_FROM_GROUP,
    payload: screen,
  };
}

export function manageCampaignName(name) {
  return {
    type: MANAGE_CAMPAIGN_NAME,
    payload: name,
  };
}

export function manageCampaignDesc(desc) {
  return {
    type: MANAGE_CAMPAIGN_DESC,
    payload: desc,
  };
}

export function selectCampaignForManage(data) {
  return {
    type: SELECT_CAMPAIGN_FOR_MANAGE,
    payload: data,
  };
}

export function assignScreensToGroupRequest() {
  return {
    type: ASSIGN_SCREENS_TO_GROUP_REQUEST,
  };
}

export function assignScreensToGroupSuccess(data) {
  return {
    type: ASSIGN_SCREENS_TO_GROUP_SUCCESS,
    payload: data,
  };
}

export function assignScreensToGroupFailure(error) {
  return {
    type: ASSIGN_SCREENS_TO_GROUP_FAILURE,
    payload: error,
  };
}

export function selectedGroupForAssigningScreens(group) {
  return {
    type: SELECTED_GROUP_FOR_ASSIGNING_SCREENS,
    payload: group,
  };
}

export function selectedScreenForAdditionalInfo(screen) {
  return {
    type: SELECTED_SCREEN_FOR_DETAILS,
    payload: screen,
  };
}

export function selectedGroupForManageGroups(group) {
  return {
    type: SELECTED_GROUP_FOR_MANAGE_GROUPS,
    payload: group,
  };
}

export function resetNewGroupResponse() {
  return {
    type: RESET_NEW_GROUP_RESPONSE,
  };
}

export function resetUpdateScreenResponse() {
  return {
    type: RESET_UPDATE_SCREEN_RESPONSE,
  };
}

export function fetchForManagingCampaignRequest() {
  return {
    type: FETCH_FOR_MANAGING_CAMPAIGN_REQUEST,
  };
}

export function fetchForManagingCampaignFailure(error) {
  return {
    type: FETCH_FOR_MANAGING_CAMPAIGN_FAILURE,
    payload: error,
  };
}

export function fetchCustomerCampaignsSuccess(data) {
  return {
    type: FETCH_CUSTOMER_CAMPAIGNS_SUCCESS,
    payload: data,
  };
}

export function toogleTimePicker() {
  return {
    type: TOGGLE_TIME_PICKER,
  };
}

export function updateCampaignName(value) {
  return {
    type: UPDATE_CAMPAIGN_NAME,
    payload: value,
  };
}

export function updateCampaignDesc(value) {
  return {
    type: UPDATE_CAMPAIGN_DESC,
    payload: value,
  };
}

export function updateCampaignStartDate(value, type) {
  return {
    type: UPDATE_CAMPAIGN_START_DATE,
    payload: { value, type },
  };
}

export function updateCampaignEndDate(value, type) {
  return {
    type: UPDATE_CAMPAIGN_END_DATE,
    payload: { value, type },
  };
}

export function updateCampaignStartTime(value, type) {
  return {
    type: UPDATE_CAMPAIGN_START_TIME,
    payload: { value, type },
  };
}

export function updateCampaignEndTime(value, type) {
  return {
    type: UPDATE_CAMPAIGN_END_TIME,
    payload: { value, type },
  };
}

export function updateCampaignSuccess(message) {
  return {
    type: UPDATE_CAMPAIGN_SUCCESS,
    payload: message,
  };
}

export function updateCampaignVideos(video) {
  return {
    type: UPDATE_CAMPAIGN_VIDEOS,
    payload: video,
  };
}

export function updateCampaignLinks(links) {
  return {
    type: ADD_OR_REMOVE_LINKS_FOR_CREATE_CAMPAIGN,
    payload: links,
  };
}

export function selectScreenForCreateCampaign(screen) {
  return {
    type: SELECT_SCREEN_FOR_CREATE_CAMPAIGN,
    payload: screen,
  };
}

export function deSelectScreenForCreateCampaign(screen) {
  return {
    type: DESELECT_SCREEN_FOR_CREATE_CAMPAIGN,
    payload: screen,
  };
}

export function selectScreenForManageDisplays(screen) {
  return {
    type: SELECT_SCREEN_FOR_MANAGE_DISPLAYS,
    payload: screen,
  };
}

export function deSelectScreenForManageDisplays(screen) {
  return {
    type: DESELECT_SCREEN_FOR_MANAGE_DISPLAYS,
    payload: screen,
  };
}

export function addOrRemoveMedia(media) {
  return {
    type: ADD_OR_REMOVE_MEDIA_FOR_CREATE_CAMPAIGN,
    payload: media,
  };
}

export function changeVideoOrderInArray(originalIndex, indexToMove) {
  return {
    type: CHANGE_VIDEO_ORDER_IN_ARRAY,
    payload: { originalIndex, indexToMove },
  };
}

export function resetSelectedGroupForManageGroups() {
  return {
    type: RESET_SELECTED_GROUP_FOR_MANAGE_GROUPS,
  };
}

export function manageScreenName(name) {
  return {
    type: MANAGE_SCREEN_NAME,
    payload: name,
  };
}

export function manageScreenAddDefaultVideo(video) {
  return {
    type: MANAGE_SCREEN_ADD_DEFAULT_VIDEO,
    payload: video,
  };
}

export function manageScreenRemoveDefaultVideo(screen) {
  return {
    type: MANAGE_SCREEN_REMOVE_DEFAULT_VIDEO,
    payload: screen,
  };
}

export function manageScreenGroup(screen) {
  return {
    type: MANAGE_SCREEN_GROUP,
    payload: screen,
  };
}

export function manageScreenStatus(screen) {
  return {
    type: MANAGE_SCREEN_STATUS,
    payload: screen,
  };
}

export function updateScreenRequest() {
  return {
    type: UPDATE_SCREEN_REQUEST,
  };
}

export function updateScreenSuccess(data) {
  return {
    type: UPDATE_SCREEN_SUCCESS,
    payload: data,
  };
}

export function updateScreenFailure(error) {
  return {
    type: UPDATE_SCREEN_FAILURE,
    payload: error,
  };
}
