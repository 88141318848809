export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';
export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export function fetchNotificationsRequest() {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
}

export function fetchNotificationsSuccess(data) {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
}

export function fetchNotificationsFailure(error) {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
}

export function deleteNotificationRequest() {
  return {
    type: DELETE_NOTIFICATION_REQUEST,
  };
}

export function deleteNotificationSuccess(data) {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: data,
  };
}
export function deleteNotificationFailure(error) {
  return {
    type: DELETE_NOTIFICATION_FAILURE,
    payload: error,
  };
}
