import React from 'react';
import { ReactComponent as Logo } from '../../styles/icons/logoIcon.svg';

const LoadingSpinner = () => (
  <div className="load">
    <div className="load__icon-wrap">
      <Logo />
    </div>
  </div>
);

export default LoadingSpinner;
