/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './containers/App/App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'http://826c5eddaff747d796bd1fbbebe690c7@172.16.100.20:9000/5' });
}

render(
  <App />,
  document.getElementById('root'),
);
