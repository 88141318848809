import {
  SELECT_SUPPORT_FILE_TYPE,
} from '../actions/supportAppActions';

const initialState = {
  error: '',
  loading: false,
  selectedType: 'all',
};

function supportAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_SUPPORT_FILE_TYPE:
      return {
        ...state,
        selectedType: action.payload,
      };
    default:
      return state;
  }
}

export default supportAppReducer;
