export const SELECT_MANAGE_APP_ROUTE = 'SELECT_MANAGE_APP_ROUTE';
export const DESELECT_MANAGE_ROUTES = 'DESELECT_MODULE_FOR_MANAGE_APP';
export const UPDATE_GEO_DATA_REQUEST = 'UPDATE_GEO_DATA_REQUEST';
export const UPDATE_GEO_DATA_SUCCESS = 'UPDATE_GEO_DATA_SUCCESS';
export const UPDATE_GEO_DATA_FAILURE = 'UPDATE_GEO_DATA_FAILURE';
export const FETCH_CURRENT_CONFIG_REQUEST = 'FETCH_CURRENT_CONFIG_REQUEST';
export const FETCH_WIFI_CONFIG_SUCCESS = 'FETCH_WIFI_CONFIG_SUCCESS';
export const FETCH_LED_CONFIG_SUCCESS = 'FETCH_LED_CONFIG_SUCCESS';
export const FETCH_HEATING_CONFIG_SUCCESS = 'FETCH_HEATING_CONFIG_SUCCESS';
// export const FETCH_PW_CONFIG_SUCCESS = 'FETCH_PW_CONFIG_SUCCESS';
export const FETCH_CURRENT_CONFIG_FAILURE = 'FETCH_CURRENT_CONFIG_FAILURE';
export const SELECT_MANAGE_APP_MODULE_CONTROL_BAR = 'SELECT_MANAGE_APP_MODULE_CONTROL_BAR';
export const FETCH_POWER_BUTTON_CONFIG_SUCCESS = 'FETCH_POWER_BUTTON_CONFIG_SUCCESS';
export const SET_WIFI_HOMEPAGE_REQUEST = 'SET_WIFI_HOMEPAGE_REQUEST';
export const SET_WIFI_HOMEPAGE_SUCCESS = 'SET_WIFI_HOMEPAGE_SUCCESS';
export const SET_WIFI_HOMEPAGE_FAILURE = 'SET_WIFI_HOMEPAGE_FAILURE';
export const UPDATE_WIFI_SCHEDULE = 'UPDATE_WIFI_SCHEDULE';
export const REQUEST_GEO_LOCATING_REQUEST = 'REQUEST_GEO_LOCATING_REQUEST';
export const REQUEST_GEO_LOCATING_FAILURE = 'REQUEST_GEO_LOCATING_FAILURE';
export const ADD_PRODUCTS_FOR_POWER_BUTTON_CONFIG = 'ADD_PRODUCTS_FOR_POWER_BUTTON_CONFIG';
export const UPDATE_POWER_STATE_REQUEST = 'UPDATE_POWER_STATE_REQUEST';
export const UPDATE_POWER_STATE_SUCCESS = 'UPDATE_POWER_STATE_SUCCESS';
export const UPDATE_POWER_STATE_FAILURE = 'UPDATE_POWER_STATE_FAILURE';
export const SET_NEW_CONFIG_REQUEST = 'SET_NEW_CONFIG_REQUEST';
export const SET_NEW_WIFI_CONFIG_SUCCESS = 'SET_NEW_WIFI_CONFIG_SUCCESS';
export const SET_NEW_HEATING_CONFIG_SUCCESS = 'SET_NEW_HEATING_CONFIG_SUCCESS';
export const SET_NEW_CONFIG_FAILURE = 'SET_NEW_CONFIG_FAILURE';
export const UPDATE_LED_VALUE_FOR_SPRING = 'UPDATE_LED_VALUE_FOR_SPRING';
export const UPDATE_LED_VALUE_FOR_SUMMER = 'UPDATE_LED_VALUE_FOR_SUMMER';
export const UPDATE_LED_VALUE_FOR_FALL = 'UPDATE_LED_VALUE_FOR_FALL';
export const UPDATE_LED_VALUE_FOR_WINTER = 'UPDATE_LED_VALUE_FOR_WINTER';
export const SET_NEW_LED_CONFIG_SUCCESS = 'SET_NEW_LED_CONFIG_SUCCESS';
export const UPDATE_HEATING_SCHEDULE = 'UPDATE_HEATING_SCHEDULE';
export const UPDATE_TEMPERATURE = 'UPDATE_TEMPERATURE';
export const SELECTED_PRODUCT_FOR_MANAGE = 'SELECTED_PRODUCT_FOR_MANAGE';
export const MANAGE_LOCATION = 'MANAGE_LOCATION';
export const MANAGE_LATITUDE = 'MANAGE_LATITUDE';
export const MANAGE_LONGITUDE = 'MANAGE_LONGITUDE';
export const RESET_GEO_DATA_RESPONSE = 'RESET_GEO_DATA_RESPONSE';
export const MANAGE_LANDING_PAGE = 'MANAGE_LANDING_PAGE';
export const RESET_WIFI_RESPONSE = 'RESET_WIFI_RESPONSE';
export const UPDATE_TERRA_LOCK_SCHEDULE = 'UPDATE_TERRA_LOCK_SCHEDULE';
export const FETCH_TERRA_LOCK_CONFIG_SUCCESS = 'FETCH_TERRA_LOCK_CONFIG_SUCCESS';
export const SET_NEW_TERRA_LOCK_CONFIG_SUCCESS = 'SET_NEW_TERRA_LOCK_CONFIG_SUCCESS';
export function selectRoute(route) {
  return {
    type: SELECT_MANAGE_APP_ROUTE,
    payload: route,
  };
}

export function deSelectedRoutes() {
  return {
    type: DESELECT_MANAGE_ROUTES,
  };
}

export function fetchCurrentConfigRequest() {
  return {
    type: FETCH_CURRENT_CONFIG_REQUEST,
  };
}

export function fetchWifiConfigSuccess(data) {
  return {
    type: FETCH_WIFI_CONFIG_SUCCESS,
    payload: data,
  };
}

export function fetchTerraLockConfigSuccess(data) {
  return {
    type: FETCH_TERRA_LOCK_CONFIG_SUCCESS,
    payload: data,
  };
}

export function fetchHeatingConfigSuccess(data) {
  return {
    type: FETCH_HEATING_CONFIG_SUCCESS,
    payload: data,
  };
}

export function fetchLEDConfigSuccess(data) {
  return {
    type: FETCH_LED_CONFIG_SUCCESS,
    payload: data,
  };
}

/* export function fetchPWConfigSuccess(data) {
  return {
    type: FETCH_PW_CONFIG_SUCCESS,
    payload: data,
  };
} */

export function fetchCurrentConfigFailure(error) {
  return {
    type: FETCH_CURRENT_CONFIG_FAILURE,
    payload: error,
  };
}

export function updateGeoDataRequest() {
  return {
    type: UPDATE_GEO_DATA_REQUEST,
  };
}

export function updateGeoDataSuccess(data) {
  return {
    type: UPDATE_GEO_DATA_SUCCESS,
    payload: data,
  };
}

export function updateGeoDataFailure(error) {
  return {
    type: UPDATE_GEO_DATA_FAILURE,
    payload: error,
  };
}

export function selectControlBar(control) {
  return {
    type: SELECT_MANAGE_APP_MODULE_CONTROL_BAR,
    payload: control,
  };
}

export function fetchPowerButtonConfigSuccess(data) {
  return {
    type: FETCH_POWER_BUTTON_CONFIG_SUCCESS,
    payload: data,
  };
}

export function setWifiHomepageRequest() {
  return {
    type: SET_WIFI_HOMEPAGE_REQUEST,
  };
}

export function setWifiHomepageSuccess(data) {
  return {
    type: SET_WIFI_HOMEPAGE_SUCCESS,
    payload: data,
  };
}

export function setWifiHomepageFailure(error) {
  return {
    type: SET_WIFI_HOMEPAGE_FAILURE,
    payload: error,
  };
}

export function updateWifiSchedule(data) {
  return {
    type: UPDATE_WIFI_SCHEDULE,
    payload: data,
  };
}

export function updateTerraLockSchedule(data) {
  return {
    type: UPDATE_TERRA_LOCK_SCHEDULE,
    payload: data,
  };
}

export function updateHeatingSchedule(data) {
  return {
    type: UPDATE_HEATING_SCHEDULE,
    payload: data,
  };
}

export function updateTemperature(data) {
  return {
    type: UPDATE_TEMPERATURE,
    payload: data,
  };
}

export function requestGeoLocatingRequest() {
  return {
    type: REQUEST_GEO_LOCATING_REQUEST,
  };
}

export function requestGeoLocatingFailure(error) {
  return {
    type: REQUEST_GEO_LOCATING_FAILURE,
    payload: error,
  };
}

export function addProductsForPowerButtonConfig(product) {
  return {
    type: ADD_PRODUCTS_FOR_POWER_BUTTON_CONFIG,
    payload: product,
  };
}

export function updatePowerStateRequest() {
  return {
    type: UPDATE_POWER_STATE_REQUEST,
  };
}

export function updatePowerStateSuccess(data) {
  return {
    type: UPDATE_POWER_STATE_SUCCESS,
    payload: data,
  };
}

export function updatePowerStateFailure(error) {
  return {
    type: UPDATE_POWER_STATE_FAILURE,
    payload: error,
  };
}

export function setNewConfigRequest() {
  return {
    type: SET_NEW_CONFIG_REQUEST,
  };
}

export function setNewWifiConfigSuccess(data) {
  return {
    type: SET_NEW_WIFI_CONFIG_SUCCESS,
    payload: data,
  };
}

export function setNewTerraLockConfigSuccess(data) {
  return {
    type: SET_NEW_TERRA_LOCK_CONFIG_SUCCESS,
    payload: data,
  };
}

export function setNewHeatingConfigSuccess(data) {
  return {
    type: SET_NEW_HEATING_CONFIG_SUCCESS,
    payload: data,
  };
}

export function setNewConfigFailure(error) {
  return {
    type: SET_NEW_CONFIG_FAILURE,
    payload: error,
  };
}

export function updateLEDValueForSpring(data) {
  return {
    type: UPDATE_LED_VALUE_FOR_SPRING,
    payload: data,
  };
}

export function updateLEDValueForSummer(data) {
  return {
    type: UPDATE_LED_VALUE_FOR_SUMMER,
    payload: data,
  };
}

export function updateLEDValueForFall(data) {
  return {
    type: UPDATE_LED_VALUE_FOR_FALL,
    payload: data,
  };
}

export function updateLEDValueForWinter(data) {
  return {
    type: UPDATE_LED_VALUE_FOR_WINTER,
    payload: data,
  };
}

export function setNewLEDConfigSuccess(data) {
  return {
    type: SET_NEW_LED_CONFIG_SUCCESS,
    payload: data,
  };
}

export function addSelectedProductForManage(product) {
  return {
    type: SELECTED_PRODUCT_FOR_MANAGE,
    payload: product,
  };
}

export function manageLongitude(data) {
  return {
    type: MANAGE_LONGITUDE,
    payload: data,
  };
}

export function manageLatitude(data) {
  return {
    type: MANAGE_LATITUDE,
    payload: data,
  };
}

export function manageLocation(data) {
  return {
    type: MANAGE_LOCATION,
    payload: data,
  };
}

export function resetGeoDataResponse() {
  return {
    type: RESET_GEO_DATA_RESPONSE,
  };
}

export function resetWifiResponse() {
  return {
    type: RESET_WIFI_RESPONSE,
  };
}

export function manageLandingPage(data) {
  return {
    type: MANAGE_LANDING_PAGE,
    payload: data,
  };
}
