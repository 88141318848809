import React, { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Welcome = lazy(() => import('../../Welcome'));
const LoginModal = lazy(() => import('../../Authentication/Login'));
const Register = lazy(() => import('../../Authentication/Register'));
const VerificationPage = lazy(() => import('../../Authentication/Register/Verification/VerificationPage'));
const CustomerRegistered = lazy(() => import('../../Authentication/Register/SuccessPages/CustomerRegistered'));
const CompanyRegistered = lazy(() => import('../../Authentication/Register/SuccessPages/CompanyRegistered'));
const ForgotPasswordConnected = lazy(() => import('../../Authentication/ForgotPassword'));
const NewPasswordConnected = lazy(() => import('../../Authentication/NewPassword'));
const ActivationLinkConnected = lazy(() => import('../../Authentication/ActivationLink'));
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));
const Unauthorized = lazy(() => import('../../Default/Unauthorized'));
const SharedLink = lazy(() => import('../../PublicInfo/SharedLink'));
const ApiDoc = lazy(() => import('../../PublicInfo/ApiDoc'));
const Login2FA = lazy(() => import('../../Authentication/2FA'));
const AirQualityIndexPublicLink = lazy(() => import('../../PublicInfo/AirQualityIndexPublicInfo'));

const MyRouter = ({ authenticated }) => (
  <main>
    <Routes>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/login" element={<LoginModal />} />
      <Route path="/2fa" element={<Login2FA />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register-success" element={<CustomerRegistered />} />
      <Route path="/register-success-company" element={<CompanyRegistered />} />
      <Route path="/forgot-password" element={<ForgotPasswordConnected />} />
      <Route path="/new-password/:newPassKey" element={<NewPasswordConnected />} />
      <Route path="/get-activation-link" element={<ActivationLinkConnected />} />
      <Route path="/verify/:verificationKey" element={<VerificationPage />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/public/campaign/:campaignHash" element={<SharedLink />} />
      <Route path="/public/air-quality/:aerysID" element={<AirQualityIndexPublicLink />} />
      <Route path="/public-api/api-doc" element={<ApiDoc />} />
      <Route
        path="/*"
        element={(
          <RequireAuth redirectTo="/welcome" authenticated={authenticated}>
            <PrivateRoutes />
          </RequireAuth>
      )}
      />
    </Routes>
  </main>
);

const RequireAuth = ({ children, redirectTo, authenticated }) => (
  authenticated ? children : <Navigate to={redirectTo} />
);

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  redirectTo: PropTypes.string.isRequired,
  authenticated: PropTypes.bool.isRequired,
};

MyRouter.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { authenticated } = state.authentication;
  return {
    authenticated,
  };
}

export default connect(mapStateToProps)(MyRouter);
