import {
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAILURE,
  GET_CUSTOMER_APPS_REQUEST,
  GET_CUSTOMER_APPS_SUCCESS,
  GET_CUSTOMER_APPS_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
  RESET_USER_PASSWORD_RESPONSE,
  RESET_USER_PROFILE_RESPONSE,
  RESET_AVATAR_UPLOAD_RESPONSE,
  RESET_PUBLIC_API_RESPONSE,
  UPDATE_BACKGROUND_REQUEST,
  UPDATE_BACKGROUND_SUCCESS,
  UPDATE_BACKGROUND_FAILURE,
  RESET_BACKGROUND_RESPONSE,
  UPDATE_TOOLTIP_STATUS_REQUEST,
  UPDATE_TOOLTIP_STATUS_SUCCESS,
  UPDATE_TOOLTIP_STATUS_FAILURE,
  FETCH_ACTIVE_SESSIONS_REQUEST,
  FETCH_ACTIVE_SESSIONS_SUCCESS,
  FETCH_ACTIVE_SESSIONS_FAILURE,
  DELETE_SESSIONS_REQUEST,
  DELETE_SESSIONS_SUCCESS,
  DELETE_SESSIONS_FAILURE,
  RESET_SESSIONS_RESPONSE,
  NOTIFICATIONS_API_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_API_FAILURE,
  DELETE_NOTIFICATION_SUCCESS,
  GET_ALL_CUSTOMERS_FROM_COMPANY_SUCCESS,
  GET_ALL_CUSTOMERS_FROM_COMPANY_FAILURE,
  GET_ALL_CUSTOMERS_FROM_COMPANY_REQUEST,
  SELECT_FILTER_FOR_COMPANY_USERS_LIST,
  SELECTED_USER_FOR_EDIT,
  EDIT_COMPANY_USER_FIRST_NAME,
  EDIT_COMPANY_USER_LAST_NAME,
  EDIT_COMPANY_USER_PROFILE_IMAGE,
  EDIT_COMPANY_USER_EMAIL,
  RESET_COMPANY_USER_INFO,
  EDIT_COMPANY_USER_ROLE,
  CHECK_CUSTOMER_EMAIL_REQUEST,
  CHECK_CUSTOMER_EMAIL_SUCCESS,
  CHECK_CUSTOMER_EMAIL_FAILURE,
  ADD_NEW_CUSTOMER_EMAIL,
  ADD_ROLE_TO_CUSTOMER,
  INVITE_TO_COMPANY_REQUEST,
  INVITE_TO_COMPANY_SUCCESS,
  INVITE_TO_COMPANY_FAILURE,
  RESET_NEW_CUSTOMER_RESPONSE,
  ADD_NEW_CUSTOMER_FIRST_NAME,
  ADD_NEW_CUSTOMER_LAST_NAME,
  SYNC_PERMISSIONS_FAILURE,
  SYNC_PERMISSIONS_REQUEST,
  SYNC_PERMISSIONS_SUCCESS,
  REMOVE_CUSTOMER_FAILURE,
  REMOVE_CUSTOMER_REQUEST,
  REMOVE_CUSTOMER_SUCCESS,
  SELECT_PERMISSION,
  DESELECT_PERMISSION,
  RESET_PERMISSIONS_RESPONSE,
  GET_ALL_PERMISSIONS_FAILURE,
  GET_ALL_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_REQUEST,
  ADD_NEW_CUSTOMER_PERMISSIONS,
  SELECT_NEW_CUSTOMER_PERMISSION,
  DESELECT_NEW_CUSTOMER_PERMISSION,
  ADD_NEW_CUSTOMER_APPLICATION,
  REGENERATE_API_KEY_FAILURE,
  REGENERATE_API_KEY_REQUEST,
  REGENERATE_API_KEY_SUCCESS,
  ACCEPT_TERMS_FAILURE,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
} from '../actions/userAction';

const initialState = {
  loading: false,
  userInfo: {
    lang: 'en',
    tooltip: {
      setup: true,
      manage: true,
      sensors: true,
      waste: true,
      homepage: true,
      support: true,
    },
  },
  userApps: [],
  errorMessage: '',
  userProfileResponse: '',
  updatePasswordResponse: '',
  updateBackgroundResponse: '',
  updateTooltipResponse: '',
  activeSessions: {},
  deletedSessionResponse: '',
  notifications: [],
  notificationDeletedMsg: '',
  companyUsersList: [],
  activeFilterCompanyUsers: 'all',
  selectedUser: {},
  isCustomerAvailable: '',
  createCustomerForCompany: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    permissions: [],
    application: [],
  },
  inviteToCompanyResponse: '',
  inputedEmail: '',
  userPermissionsSyncResponse: '',
  customerRemovedResponse: '',
  allPermissions: [],
  apiKeyResponse: '',
  acceptedTOS: '',
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CUSTOMERS_FROM_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CUSTOMERS_FROM_COMPANY_SUCCESS:
      return {
        ...state,
        companyUsersList: action.payload,
        loading: false,
      };
    case GET_ALL_CUSTOMERS_FROM_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
      };
    case FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case GET_CUSTOMER_APPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CUSTOMER_APPS_SUCCESS:
      return {
        ...state,
        userApps: action.payload,
        loading: false,
      };
    case GET_CUSTOMER_APPS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userProfileResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        updatePasswordResponse: '',
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        updatePasswordResponse: '',
      };
    case RESET_USER_PASSWORD_RESPONSE:
      return {
        ...state,
        loading: false,
        updatePasswordResponse: '',
      };
    case ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        acceptedTOS: action.payload,
        loading: false,
        errorMessage: '',
      };
    case ACCEPT_TERMS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        acceptedTOS: '',
      };
    case ACCEPT_TERMS_REQUEST:
      return {
        ...state,
        loading: false,
        acceptedTOS: '',
      };
    case RESET_PUBLIC_API_RESPONSE:
      return {
        ...state,
        loading: false,
        apiKeyResponse: '',
      };
    case RESET_USER_PROFILE_RESPONSE:
      return {
        ...state,
        loading: false,
        userProfileResponse: '',
      };
    case RESET_AVATAR_UPLOAD_RESPONSE:
      return {
        ...state,
        loading: false,
        avatarUploaded: '',
      };
    case UPDATE_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        updateBackgroundResponse: '',
      };
    case UPDATE_BACKGROUND_SUCCESS:
      return {
        ...state,
        updateBackgroundResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case UPDATE_BACKGROUND_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        updateBackgroundResponse: '',
      };
    case RESET_BACKGROUND_RESPONSE:
      return {
        ...state,
        loading: false,
        updateBackgroundResponse: '',
      };
    case UPDATE_TOOLTIP_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        updateTooltipResponse: '',
        errorMessage: '',
      };
    case UPDATE_TOOLTIP_STATUS_SUCCESS:
      return {
        ...state,
        updateTooltipResponse: action.payload,
        loading: false,
      };
    case UPDATE_TOOLTIP_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateTooltipResponse: '',
      };
    case FETCH_ACTIVE_SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        activeSessions: {},
      };
    case FETCH_ACTIVE_SESSIONS_SUCCESS:
      return {
        ...state,
        activeSessions: action.payload,
        loading: false,
      };
    case FETCH_ACTIVE_SESSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        activeSessions: {},
        errorMessage: action.payload,
      };
    case DELETE_SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        deletedSessionResponse: '',
      };
    case DELETE_SESSIONS_SUCCESS:
      return {
        ...state,
        deletedSessionResponse: action.payload,
        loading: false,
      };
    case DELETE_SESSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        deletedSessionResponse: '',
        errorMessage: action.payload,
      };
    case RESET_SESSIONS_RESPONSE:
      return {
        ...state,
        loading: false,
        deletedSessionResponse: '',
      };
    case NOTIFICATIONS_API_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
      };
    case NOTIFICATIONS_API_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationDeletedMsg: action.payload,
        errorMessage: '',
      };
    case SELECT_FILTER_FOR_COMPANY_USERS_LIST:
      return {
        ...state,
        activeFilterCompanyUsers: action.payload,
      };
    case SELECTED_USER_FOR_EDIT:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case EDIT_COMPANY_USER_FIRST_NAME:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          first_name: action.payload,
        },
      };
    case EDIT_COMPANY_USER_LAST_NAME:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          last_name: action.payload,
        },
      };
    case EDIT_COMPANY_USER_EMAIL:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          email: action.payload,
        },
      };
    case EDIT_COMPANY_USER_PROFILE_IMAGE:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          profile_image: action.payload,
          profileImageUpdated: true,
        },
      };
    case EDIT_COMPANY_USER_ROLE:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          company_role: action.payload,
        },
      };
    case RESET_COMPANY_USER_INFO:
      return {
        ...state,
        selectedUser: {},
        userProfileResponse: '',
      };
    case CHECK_CUSTOMER_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case CHECK_CUSTOMER_EMAIL_SUCCESS:
      return {
        ...state,
        isCustomerAvailable: action.payload.success,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          firstName: action.payload.data.first_name,
          lastName: action.payload.data.last_name,
          email: action.payload.data.email,
          role: action.payload.data.company_role,
          permissions: action.payload.data.permissions,
          application: action.payload.data.application,
        },
        loading: false,
        errorMessage: '',
      };
    case CHECK_CUSTOMER_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case ADD_NEW_CUSTOMER_EMAIL:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          email: action.payload,
        },
        inputedEmail: action.payload,
      };
    case ADD_ROLE_TO_CUSTOMER:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          role: action.payload,
        },
      };
    case ADD_NEW_CUSTOMER_APPLICATION:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          application: action.payload,
        },
      };
    case ADD_NEW_CUSTOMER_PERMISSIONS:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          permissions: action.payload,
        },
      };
    case SELECT_NEW_CUSTOMER_PERMISSION:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          permissions: [...state.createCustomerForCompany.permissions, action.payload],
        },
      };
    case DESELECT_NEW_CUSTOMER_PERMISSION:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          permissions: state.createCustomerForCompany.permissions.filter((item) => item !== action.payload),
        },
      };
    case INVITE_TO_COMPANY_SUCCESS:
      return {
        ...state,
        inviteToCompanyResponse: action.payload,
        loading: false,
      };
    case INVITE_TO_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case INVITE_TO_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case RESET_NEW_CUSTOMER_RESPONSE:
      return {
        ...state,
        loading: false,
        isCustomerAvailable: '',
        createCustomerForCompany: {
          firstName: '',
          lastName: '',
          email: '',
          role: '',
          permissions: [],
        },
        inviteToCompanyResponse: '',
        errorMessage: '',
      };
    case ADD_NEW_CUSTOMER_FIRST_NAME:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          firstName: action.payload,
        },
      };
    case ADD_NEW_CUSTOMER_LAST_NAME:
      return {
        ...state,
        loading: false,
        createCustomerForCompany: {
          ...state.createCustomerForCompany,
          lastName: action.payload,
        },
      };
    case SYNC_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };
    case SYNC_PERMISSIONS_SUCCESS:
      return {
        ...state,
        userPermissionsSyncResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case SYNC_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case REMOVE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        customerRemovedResponse: '',
      };
    case REMOVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerRemovedResponse: action.payload,
        loading: false,
        errorMessage: '',
      };
    case REMOVE_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        customerRemovedResponse: '',
      };
    case SELECT_PERMISSION:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          permissions: [...state.selectedUser.permissions, action.payload],
        },
      };
    case DESELECT_PERMISSION:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          permissions: state.selectedUser.permissions.filter((item) => item !== action.payload),
        },
      };
    case RESET_PERMISSIONS_RESPONSE:
      return {
        ...state,
        loading: false,
        userPermissionsSyncResponse: '',
      };
    case GET_ALL_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allPermissions: action.payload,
        loading: false,
      };
    case GET_ALL_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case REGENERATE_API_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGENERATE_API_KEY_SUCCESS:
      return {
        ...state,
        apiKeyResponse: action.payload,
        loading: false,
      };
    case REGENERATE_API_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
export default userReducer;
