export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE';
export const VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';
export const GET_ACTIVATION_LINK_SUCCESS = 'GET_ACTIVATION_LINK_SUCCESS';
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const IS_TOKEN_ACTIVE_REQUEST = 'IS_TOKEN_ACTIVE_REQUEST';
export const IS_TOKEN_ACTIVE_SUCCESS = 'IS_TOKEN_ACTIVE_SUCCESS';
export const REMOVE_EXPIRED_TOKEN = 'REMOVE_EXPIRED_TOKEN';
export const IS_TOKEN_ACTIVE_FAILURE = 'IS_TOKEN_ACTIVE_FAILURE';

export const SAVE_LOGIN_DATA = 'SAVE_LOGIN_DATA';

export function loginRequest() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
}

export function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function registerRequest() {
  return {
    type: REGISTER_REQUEST,
  };
}

export function registerSuccess() {
  return {
    type: REGISTER_SUCCESS,
  };
}

export function registerFailure(error) {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
}

export function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error,
  };
}

export function newPasswordRequest() {
  return {
    type: NEW_PASSWORD_REQUEST,
  };
}

export function newPasswordSuccess(data) {
  return {
    type: NEW_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function newPasswordFailure(error) {
  return {
    type: NEW_PASSWORD_FAILURE,
    payload: error,
  };
}

export function verifyUserRequest() {
  return {
    type: VERIFY_USER_REQUEST,
  };
}

export function verifyUserSuccess(data) {
  return {
    type: VERIFY_USER_SUCCESS,
    payload: data,
  };
}

export function verifyUserFailure(error) {
  return {
    type: VERIFY_USER_FAILURE,
    payload: error,
  };
}

export function getActivationLinkSuccess(link) {
  return {
    type: GET_ACTIVATION_LINK_SUCCESS,
    payload: link,
  };
}

export function acceptCookies() {
  return {
    type: ACCEPT_COOKIES,
  };
}

export function isTokenActiveRequest() {
  return {
    type: IS_TOKEN_ACTIVE_REQUEST,
  };
}

export function isTokenActiveSuccess() {
  return {
    type: IS_TOKEN_ACTIVE_SUCCESS,
  };
}

export function removeExpiredToken() {
  return {
    type: REMOVE_EXPIRED_TOKEN,
  };
}

export function isTokenActiveFailure(error) {
  return {
    type: IS_TOKEN_ACTIVE_FAILURE,
    payload: error,
  };
}

export function saveLoginData(data) {
  return {
    type: SAVE_LOGIN_DATA,
    payload: data,
  };
}
