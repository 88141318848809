export const SELECT_ROUTE_FOR_DISTRIBUTOR_APP = 'SELECT_ROUTE_FOR_DISTRIBUTOR_APP';
export const GET_DISTRIBUTOR_PRODUCTS_REQUEST = 'GET_DISTRIBUTOR_PRODUCTS_REQUEST';
export const GET_DISTRIBUTOR_PRODUCTS_SUCCESS = 'GET_DISTRIBUTOR_PRODUCTS_SUCCESS';
export const GET_DISTRIBUTOR_PRODUCTS_FAILURE = 'GET_DISTRIBUTOR_PRODUCTS_FAILURE';
export const SELECT_DATE_FOR_DISTRIBUTOR_APP = 'SELECT_DATE_FOR_DISTRIBUTOR_APP';
export const GET_PRODUCT_STATUS_REQUEST = 'GET_PRODUCT_STATUS_REQUEST';
export const GET_PRODUCT_STATUS_SUCCESS = 'GET_PRODUCT_STATUS_SUCCESS';
export const GET_PRODUCT_STATUS_YESTERDAY_SUCCESS = 'GET_PRODUCT_STATUS_YESTERDAY_SUCCESS';
export const GET_PRODUCT_STATUS_WEEK_SUCCESS = 'GET_PRODUCT_STATUS_WEEK_SUCCESS';
export const GET_PRODUCT_STATUS_MONTH_SUCCESS = 'GET_PRODUCT_STATUS_MONTH_SUCCESS';
export const GET_PRODUCT_STATUS_FAILURE = 'GET_PRODUCT_STATUS_FAILURE';

export function selectDate(date) {
  return {
    type: SELECT_DATE_FOR_DISTRIBUTOR_APP,
    payload: date,
  };
}

export function selectRoute(route) {
  return {
    type: SELECT_ROUTE_FOR_DISTRIBUTOR_APP,
    payload: route,
  };
}

export function getDistributorProductsRequest() {
  return {
    type: GET_DISTRIBUTOR_PRODUCTS_REQUEST,
  };
}

export function getDistributorProductsSuccess(data) {
  return {
    type: GET_DISTRIBUTOR_PRODUCTS_SUCCESS,
    payload: data,
  };
}

export function getDistributorProductsFailure(error) {
  return {
    type: GET_DISTRIBUTOR_PRODUCTS_FAILURE,
    payload: error,
  };
}

export function getProductStatusRequest() {
  return {
    type: GET_PRODUCT_STATUS_REQUEST,
  };
}

export function getProductStatusYesterdaySuccess(data) {
  return {
    type: GET_PRODUCT_STATUS_YESTERDAY_SUCCESS,
    payload: data,
  };
}

export function getProductStatusWeekSuccess(data) {
  return {
    type: GET_PRODUCT_STATUS_WEEK_SUCCESS,
    payload: data,
  };
}

export function getProductStatusMonthSuccess(data) {
  return {
    type: GET_PRODUCT_STATUS_MONTH_SUCCESS,
    payload: data,
  };
}

export function getProductStatusSuccess(data) {
  return {
    type: GET_PRODUCT_STATUS_SUCCESS,
    payload: data,
  };
}

export function getProductStatusFailure(error) {
  return {
    type: GET_PRODUCT_STATUS_FAILURE,
    payload: error,
  };
}
