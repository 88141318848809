import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
} from '../actions/notificationsAction';

const initialState = {
  error: '',
  loading: false,
  notificationsList: [],
  deleteNotificationResponse: '',
};

function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsList: action.payload,
        loading: false,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteNotificationResponse: action.payload,
        loading: false,
      };
    case DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default notificationsReducer;
