/* eslint-disable consistent-return */
import moment from 'moment';
import {
  SELECT_SENSOR_FOR_SENSE_APP,
  DESELECT_SENSOR_FOR_SENSE_APP,
  FETCH_SENSORS_REQUEST,
  FETCH_SENSORS_SUCCESS,
  FETCH_SENSORS_FAILURE,
  SELECT_DATE_FOR_SENSE_APP,
  FETCH_MULTIPLE_SENSORS_SUCCESS,
  FETCH_SENSORS_MONTH_SUCCESS,
  FETCH_SENSORS_WEEK_SUCCESS,
  FETCH_SENSORS_YESTERDAY_SUCCESS,
  FETCH_SENSORS_CUSTOM_SUCCESS,
  FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS,
  FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS,
  FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS,
  FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS,
  REMOVE_SENSOR_DATA,
  RESET_SELECTED_SENSOR_FOR_SENSE_APP,
  RESET_ONE_PRODUCT_DATA_STATE,
  RESET_MULTIPLE_PRODUCTS_DATA_STATE,
} from '../actions/senseAppActions';

const now = moment().format('DD.MM.YYYY');

const initialState = {
  selectedSensors: [],
  sensorsData: [],
  sensorsDataYesterday: [],
  sensorsDataWeek: [],
  sensorsDataMonth: [],
  sensorsDataCustom: [],
  multipleSensorData: [],
  multipleSensorDataYesterday: [],
  multipleSensorDataWeek: [],
  multipleSensorDataMonth: [],
  multipleSensorDataCustom: [],
  selectedTimePeriod: {
    startDate: now,
    endDate: now,
    title: 'today',
  },
  sensorDataAirGlobalIndex: [],
};

function senseAppReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_SENSOR_FOR_SENSE_APP:
      return {
        ...state,
        selectedSensors: [...state.selectedSensors, action.payload],
        loading: false,
      };
    case DESELECT_SENSOR_FOR_SENSE_APP:
      return {
        ...state,
        selectedSensors: state.selectedSensors.filter((item) => item !== action.payload),
      };
    case RESET_SELECTED_SENSOR_FOR_SENSE_APP:
      return {
        ...state,
        selectedSensors: [],
        loading: false,
      };
    case RESET_ONE_PRODUCT_DATA_STATE:
      return {
        ...state,
        sensorsData: [],
        sensorsDataYesterday: [],
        sensorsDataWeek: [],
        sensorsDataMonth: [],
        sensorsDataCustom: [],
        loading: false,
      };
    case RESET_MULTIPLE_PRODUCTS_DATA_STATE:
      return {
        ...state,
        multipleSensorData: [],
        multipleSensorDataYesterday: [],
        multipleSensorDataWeek: [],
        multipleSensorDataMonth: [],
        multipleSensorDataCustom: [],
        loading: false,
      };
    case FETCH_SENSORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SENSORS_SUCCESS:
      if (!state.sensorsData.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          multipleSensorData: [],
          sensorsData: [...state.sensorsData, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
        multipleSensorData: [],
        loading: false,
      };
    case FETCH_MULTIPLE_SENSORS_SUCCESS:
      if (!state.multipleSensorData.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          sensorsData: [],
          multipleSensorData: [...state.multipleSensorData, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
        sensorsData: [],
        multipleSensorData: [action.payload],
        loading: false,
      };
    case FETCH_SENSORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_DATE_FOR_SENSE_APP:
      return {
        ...state,
        selectedTimePeriod: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          title: action.payload.title,
        },
      };
    case FETCH_SENSORS_MONTH_SUCCESS:
      if (!state.sensorsDataMonth.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataMonth: [...state.sensorsDataMonth, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_SENSORS_CUSTOM_SUCCESS: {
      const currentState = state.sensorsDataCustom.filter((item) => item.module_id !== action.payload.module_id);
      return {
        ...state,
        sensorsDataCustom: [...currentState, action.payload],
        loading: false,
      };
    }
    case FETCH_SENSORS_WEEK_SUCCESS:
      if (!state.sensorsDataWeek.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataWeek: [...state.sensorsDataWeek, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_SENSORS_YESTERDAY_SUCCESS:
      if (!state.sensorsDataYesterday.some((item) => item.module_id === action.payload.module_id)) {
        return {
          ...state,
          sensorsDataYesterday: [...state.sensorsDataYesterday, action.payload],
          loading: false,
        };
      }
      return {
        ...state,
      };
    case FETCH_MULTIPLE_SENSORS_YESTERDAY_SUCCESS:
      if (!state.multipleSensorDataYesterday.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          multipleSensorDataYesterday: [...state.multipleSensorDataYesterday, action.payload],
          loading: false,
        };
      }
      break;
    case FETCH_MULTIPLE_SENSORS_WEEK_SUCCESS:
      if (!state.multipleSensorDataWeek.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          multipleSensorDataWeek: [...state.multipleSensorDataWeek, action.payload],
          loading: false,
        };
      }
      break;
    case FETCH_MULTIPLE_SENSORS_MONTH_SUCCESS:
      if (!state.multipleSensorDataMonth.some((item) => item[0].module_id === action.payload[0].module_id)) {
        return {
          ...state,
          multipleSensorDataMonth: [...state.multipleSensorDataMonth, action.payload],
          loading: false,
        };
      }
      break;
    case FETCH_MULTIPLE_SENSORS_CUSTOM_SUCCESS:
      return {
        ...state,
        multipleSensorDataCustom: [action.payload],
        loading: false,
      };
    case REMOVE_SENSOR_DATA:

      return {
        ...state,
        sensorsData: state.sensorsData.filter((item) => item.module_id !== action.payload),
        sensorsDataYesterday: state.sensorsDataYesterday.filter((item) => item.module_id !== action.payload),
        sensorsDataWeek: state.sensorsDataWeek.filter((item) => item.module_id !== action.payload),
        sensorsDataMonth: state.sensorsDataMonth.filter((item) => item.module_id !== action.payload),
        sensorsDataCustom: state.sensorsDataCustom.filter((item) => item.module_id !== action.payload),
        multipleSensorData: state.multipleSensorData.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataYesterday:
          state.multipleSensorDataYesterday.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataWeek: state.multipleSensorDataWeek.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataMonth: state.multipleSensorDataMonth.filter((item) => item[0].module_id !== action.payload),
        multipleSensorDataCustom: state.multipleSensorDataCustom.filter((item) => item[0].module_id !== action.payload),
        loading: false,
        selectedTimePeriod: {
          startDate: now,
          endDate: now,
          title: 'today',
        },
      };
    default:
      return state;
  }
}

export default senseAppReducer;
