import {
  FETCH_DEPLOYED_PRODUCTS_REQUEST,
  FETCH_DEPLOYED_PRODUCTS_SUCCESS,
  FETCH_DEPLOYED_PRODUCTS_FAILURE,
  SELECT_FILTER_FOR_PRODUCTS_LIST,
  SELECT_PRODUCT,
  DESELECT_PRODUCT,
  SELECT_ALL_PRODUCTS,
  DESELECT_ALL_PRODUCTS,
  REQUEST_GEO_LOCATING_SUCCESS,
  UPDATE_NICKNAME_REQUEST,
  UPDATE_NICKNAME_SUCCESS,
  UPDATE_NICKNAME_FAILURE,
  RESET_NICKNAME_UPDATED_RESPONSE,
} from '../actions/deployedProductsAction';

const initialState = {
  error: '',
  loading: false,
  productsList: [],
  selectedProducts: [],
  activeFilter: 'all',
  onNicknameUpdate: '',
};

function deployedProductsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NICKNAME_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        onNicknameUpdate: '',
      };
    case UPDATE_NICKNAME_SUCCESS:
      return {
        ...state,
        onNicknameUpdate: action.payload,
        loading: false,
        error: '',
      };

    case UPDATE_NICKNAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        onNicknameUpdate: '',
      };
    case RESET_NICKNAME_UPDATED_RESPONSE:
      return {
        ...state,
        loading: false,
        error: '',
        onNicknameUpdate: '',
      };
    case FETCH_DEPLOYED_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DEPLOYED_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsList: action.payload,
        loading: false,
      };
    case FETCH_DEPLOYED_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELECT_FILTER_FOR_PRODUCTS_LIST:
      return {
        ...state,
        activeFilter: action.payload,
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selectedProducts: [...state.selectedProducts, action.payload],
      };
    case DESELECT_PRODUCT:
      return {
        ...state,
        selectedProducts: state.selectedProducts.filter((id) => id !== action.payload),
      };
    case SELECT_ALL_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.payload,
      };
    case DESELECT_ALL_PRODUCTS:
      return {
        ...state,
        selectedProducts: [],
      };
    case REQUEST_GEO_LOCATING_SUCCESS: {
      const index = state.productsList.findIndex((product) => product.id === action.payload.id);
      const newArray = [...state.productsList];
      newArray[index].requested_geo = action.payload.requested_geo;

      return {
        ...state,
        productsList: newArray,
        loading: false,
      };
    }
    default:
      return state;
  }
}

export default deployedProductsReducer;
