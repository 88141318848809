export const ACTIVATE_PRODUCT_REQUEST = 'ACTIVATE_PRODUCT_REQUEST';
export const ACTIVATE_PRODUCT_SUCCESS = 'ACTIVATE_PRODUCT_SUCCESS';
export const ACTIVATE_PRODUCT_FAILURE = 'ACTIVATE_PRODUCT_FAILURE';
export const RESET_ACTIVATION_FORM_DATA = 'RESET_ACTIVATION_FORM_DATA';

export function activateProductRequest() {
  return {
    type: ACTIVATE_PRODUCT_REQUEST,
  };
}

export function activateProductSuccess(data) {
  return {
    type: ACTIVATE_PRODUCT_SUCCESS,
    payload: data,
  };
}

export function activateProductFailure(error) {
  return {
    type: ACTIVATE_PRODUCT_FAILURE,
    payload: error,
  };
}

export function resetActivationFormData() {
  return {
    type: RESET_ACTIVATION_FORM_DATA,
  };
}
