import moment from 'moment';
import { logoutAction } from '../../api/authApi';

const authItem = localStorage.getItem('auth');
const cookies = localStorage.getItem('cookies_accepted');

export const getToken = () => {
  // if the token doesn't exist, return false
  if (!authItem) {
    return false;
  }
  // token exists
  const auth = JSON.parse(authItem);
  const now = moment();
  // compare the expiry time of the item with the current time
  if (now > auth.expiry) {
    // token has expired, so it's not valid
    logoutAction();
    return false;
  }
  // return true because token didn't expire
  return true;
};

export const cookiesExist = () => {
  if (!cookies) {
    return false;
  }

  return true;
};
