// authentication functions
// login, register, lost password, activate account
import moment from 'moment';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  registerRequest,
  registerSuccess,
  registerFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  newPasswordRequest,
  newPasswordSuccess,
  newPasswordFailure,
  verifyUserRequest,
  verifyUserSuccess,
  verifyUserFailure,
  getActivationLinkSuccess,
  isTokenActiveRequest,
  isTokenActiveSuccess,
  removeExpiredToken,
  isTokenActiveFailure,
} from '../redux/actions/authActions';

import authHeader from './authHeader';

export function loginAction(email, password, keepMeLoggedIn, google2fa) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      remember_me: keepMeLoggedIn,
      pin: google2fa,
    }),
  };

  localStorage.setItem('email', email);
  localStorage.setItem('password', password);
  localStorage.setItem('remember_me', keepMeLoggedIn);

  return (dispatch) => {
    dispatch(loginRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/login`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        const now = moment();
        const auth = {
          token: res.success.token,
          // time to live 24 hours from now
          expiry: now + 86400000,
        };

        if (keepMeLoggedIn) {
          // time to live 1 year
          const ttl = 31556952000;
          auth.expiry = now + ttl;
        }

        localStorage.setItem('auth', JSON.stringify(auth));
        // on click of Login btn dispatch api and get response
        dispatch(loginSuccess(res));

        const apiLoginResponse = dispatch(loginSuccess(res));
        const apiLoginPayload = apiLoginResponse.payload;

        // if response is 2fa required, push new route
        if (apiLoginPayload.success === 'google2fa required') {
          window.location.replace('/2fa');
        } else {
          // update path and refresh and go to home screen, 2fa is not required

          window.location.replace('/home');
          window.location.replace('/');
        }
      })
      .catch((error) => dispatch(loginFailure(error)));
  };
}

export function logoutAction() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return (dispatch) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/logout`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        localStorage.removeItem('auth');
        dispatch(logoutSuccess());
      });
  };
}

export function isTokenActive() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return (dispatch) => {
    dispatch(isTokenActiveRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/is-token-active`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        if (res.status === true) {
          dispatch(isTokenActiveSuccess());
          window.location.replace('/home');
        } else {
          dispatch(removeExpiredToken());
          localStorage.removeItem('auth');
        }
      })
      .catch((error) => dispatch(isTokenActiveFailure(error)));
  };
}

export function registerUserAction(
  firstName,
  lastName,
  email,
  password,
  countryId,
  tos,
) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      country_id: countryId,
      tos_accepted: tos,
    }),
  };

  return (dispatch) => {
    dispatch(registerRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/register`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(registerSuccess());
        // update path and refresh
        window.location.replace('/register-success');
      })
      .catch((error) => dispatch(registerFailure(error)));
  };
}

export function registerCompanyAction(
  name,
  vat,
  address,
  city,
  zipCode,
  countryId,
  firstName,
  lastName,
  email,
  password,
  tos,
) {
  const company = {
    name,
    vat,
    address,
    city,
    zip_code: zipCode,
    country_id: countryId,
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      company,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      country_id: countryId,
      tos_accepted: tos,
    }),
  };

  return (dispatch) => {
    dispatch(registerRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/register`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }
        dispatch(registerSuccess());
        // update path and refresh
        window.location.replace('/register-success-company');
      })
      .catch((error) => dispatch(registerFailure(error)));
  };
}

export function forgotPasswordAction(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({ email }),
  };

  return (dispatch) => {
    dispatch(forgotPasswordRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/forgot-password`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(forgotPasswordSuccess(res.success));
      })
      .catch((error) => dispatch(forgotPasswordFailure(error)));
  };
}

export function newPasswordAction(password, code) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({ password, verification_code: code }),
  };

  return (dispatch) => {
    dispatch(newPasswordRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/new-password`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(newPasswordSuccess(res.success));
      })
      .catch((error) => dispatch(newPasswordFailure(error)));
  };
}

export function verifyUser(verificationCode) {
  const requestOptions = {
    method: 'GET',
  };

  return (dispatch) => {
    dispatch(verifyUserRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/verify/${verificationCode}`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(verifyUserSuccess(res));
      })
      .catch((error) => dispatch(verifyUserFailure(error)));
  };
}

export function getActivationLinkAction(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    body: JSON.stringify({ email }),
  };

  return (dispatch) => {
    dispatch(verifyUserRequest());
    fetch(`${process.env.REACT_APP_BASE_URL}/customers/activation`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          throw (res.error);
        }

        dispatch(getActivationLinkSuccess(res.success));
      })
      .catch((error) => dispatch(verifyUserFailure(error)));
  };
}
