import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as reduxFormReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';

import {
  authReducer,
  countriesReducer,
  deployedProductsReducer,
  manageAppReducer,
  notificationsReducer,
  productsReducer,
  senseAppReducer,
  supportAppReducer,
  userReducer,
  distributorAppReducer,
  advertiseAppReducer,
  publicReducer,
  wasteAppReducer,
} from '../../redux/reducers/index';

const reducer = combineReducers({
  authentication: authReducer,
  advertiseApp: advertiseAppReducer,
  countries: countriesReducer,
  deployedProducts: deployedProductsReducer,
  distributorApp: distributorAppReducer,
  form: reduxFormReducer,
  manageApp: manageAppReducer,
  notifications: notificationsReducer,
  products: productsReducer,
  publicInfo: publicReducer,
  senseApp: senseAppReducer,
  supportApp: supportAppReducer,
  user: userReducer,
  wasteApp: wasteAppReducer,
});

const store = createStore(reducer, composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
  ),
));

export default store;
